import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './formationCard.module.scss';
import defaultPicture from '../../../assets/images/plante.jpg';
import { IFormation } from '../../../types/formations';
import { getApiPicture } from '../../../utils';
import Tags from '../../Tags';

const FormationCard = ({ formation }:{ formation: IFormation }) => {
  const picture = formation?.picture?.path
    ? getApiPicture(formation?.picture?.path) : defaultPicture;
  const { t } = useTranslation('formations');
  const isValid = formation.isValid.length === formation.quiz.length;
  return (
    <Link to={`/formations/${formation._id}`} className={styles.card}>
      <div className={`${styles.status} ${isValid ? styles.valid : ''}`}>
        <p>{isValid ? t('valid') : t('inProgress')}</p>
      </div>
      <div className={styles.picture}>
        {picture && <img src={picture} />}
      </div>
      <div className={styles.infos}>
        <h4>{formation.name}</h4>
        <div className={styles.progress}>
          <p>{formation?.isValid?.length}/{formation?.quiz?.length} quiz</p>
          <div className={styles['bar-container']}>
            <div
              className={styles.bar}
              style={{ width: `${(formation.isValid.length / formation.quiz.length) * 100}%` }}
            />
          </div>
        </div>
        <Tags tags={formation.tags}/>
      </div>
    </Link>
  );
};

export default FormationCard;
