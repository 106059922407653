/* eslint-disable no-alert */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { HiChevronRight, HiChevronLeft } from 'react-icons/hi2';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FaCheck } from 'react-icons/fa6';
import { postAnswerAction } from '../../../actions/userQuiz';
import {
  Answer, PictureType, QuestionType,
} from '../../../types/userQuiz';
import { getTepicPicture, removeHTMLTags } from '../../../utils';
import Portal from '../Portal';
import styles from './Question.module.scss';
import Options from '../QuestionOptions';
import PairsOptions from '../PairsOptions';
import SelectsOptions from '../SelectsOptions';
import InputsOptions from '../InputsOptions';
import { IOption } from '../../../types/questions';
import ZoomMobile from '../ZoomMobile';
import { SettingsDesktop } from '../Settings';
import AssociatePicturesOptions from '../AssociatePicturesOptions';
// import AssociatePicturesOptions from '../AssociatePicturesOptions';

const Stars = ({ isHover }: { isHover: boolean }) => (
  <svg width="31" className={styles.stars} height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
    <path opacity="0.6" d="M6.5 0L7.79613 5.20387L13 6.5L7.79613 7.79613L6.5 13L5.20387 7.79613L0 6.5L5.20387 5.20387L6.5 0Z" fill="white"/>
    <path opacity={isHover ? '1' : '0.6'} d="M21 10L22.7678 18.2322L31 20L22.7678 21.7678L21 30L19.2322 21.7678L11 20L19.2322 18.2322L21 10Z" fill="white"/>
    </g>
  </svg>
);

const Pictures = ({ pictures }: { pictures: PictureType[] }) => (
    <div className={`${styles.pictures} ${pictures.length > 0 ? styles[`col-${pictures.length}`] : styles['col-1']}`}>
      {pictures.length > 0 && pictures?.map((p: PictureType) => <div className={styles.img}
          key={p.file_name}
        >
          <img src={getTepicPicture(p)} alt={p.file_name} />
         </div>)}
    </div>
);

export default function Question({
  question,
  index,
  handleQuestionIndex,
}:{
  question : QuestionType,
  index: number,
  handleQuestionIndex: (i: number) => void,
}) {
  const { t, i18n } = useTranslation('global');
  const dispatch = useDispatch();
  const [submitIsHover, setSubmitIsHover] = useState<boolean>(false);
  const { current, settings } = useSelector((s: any) => s.userQuizReducer);
  const [showCorrection, setShowCorrection] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const quiz = current?.quiz && { ...current.quiz };
  const answers = current?.answers && { ...current.answers };
  const prevAnswer = answers?.entries?.length > 0 && [...answers.entries].find(
    (e: Answer) => e.question === question?._id,
  );

  const isSelect = question?.type === 'QRU_C_SELECTS';

  const picturesPairing = question?.type === 'QRA_R_PICTURES';

  const isPairing = question?.type === 'QRA_R_TAXONOMY' || question?.type === 'QRA_C_CHARACTERS' || question?.type === 'QRA_R_NAMES_PICTURES';

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      selectedOptions: [],
      selectedPairs: [],
      inputs: {

      },
    },
  });

  const selectedOptions = watch('selectedOptions');
  const selectedPairs = watch('selectedPairs');
  const form : any = watch();

  const showResponseActive = useMemo(() => {
    let show = false;
    if (showCorrection && prevAnswer?.isCorrect === false && question?.pairs?.length > 0) {
      show = true;
    }
    return show;
  }, [showCorrection, question?.pairs, prevAnswer]);

  const getCorrection = useMemo(() => {
    if (showCorrection && prevAnswer) {
      return {
        className: prevAnswer.isCorrect ? styles.correct : styles.wrong,
        label: prevAnswer.isCorrect ? t('current.correct', { ns: 'quiz' }) : t('current.wrong', { ns: 'quiz' }),
      };
    }
    return {

    };
  }, [showCorrection, prevAnswer]);

  const submitDisabled = useMemo(() => {
    if (question.inputs?.length > 0) {
      if (question.inputs.filter(
        (d) => (form.inputs[d.property] ? d : null),
      ).length === question.inputs.length) {
        return false;
      }
      return true;
    }
    if (isSelect) {
      const selectGroup = question.options.map((item) => item.group)
        .filter((value, i, self) => self.indexOf(value) === i);
      if (selectedOptions.length === selectGroup.length) {
        return false;
      }
      return true;
    }
    if ((question.pairs.length > 0
      && selectedPairs.length === question.pairs.length / 2) || prevAnswer) {
      return false;
    }
    if ((selectedOptions.length === 0) || prevAnswer) {
      return true;
    }
    return false;
  }, [
    selectedOptions,
    question.pairs,
    selectedPairs,
    isSelect,
    question.type,
    question.inputs,
    form,
    question.answerType,
  ]);

  async function onSubmit(values: FieldValues) {
    if (showResponseActive) {
      return setShowResponse(true);
    }
    const payload = {
      question: question._id,
      ...values,
    };
    const res = await postAnswerAction(dispatch, answers?._id, payload);

    if (res && !current?.quiz.showCorrections) {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await handleQuestionIndex(index + 1);
    } else {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setShowCorrection(true);
    }
    return null;
  }

  const speakText = () => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();
      const textArray : string[] = [];
      if (question.text) {
        const cleanTags = removeHTMLTags(question.text);
        textArray.push(cleanTags);
      }
      const options = question?.options.filter((o: IOption) => o.text).map((o :IOption) => o.text);

      if (options.length > 0) {
        options.forEach((o: string) => {
          textArray.push(o);
        });
      }
      const inputs = question?.inputs.filter((o: any) => o.label).map((o : any) => o.label);
      if (inputs.length > 0) {
        inputs.forEach((i: string) => {
          textArray.push(i);
        });
      }
      const pairs = question?.pairs.filter(
        (o: any) => o.text,
      ).sort((a, b) => (a?.group > b?.group ? 1 : -1)).map(
        (o : any) => o.text,
      );
      if (pairs.length > 0) {
        pairs.forEach((i: string) => {
          textArray.push(i);
        });
      }
      textArray.forEach(async (text) => {
        // eslint-disable-next-line no-promise-executor-return
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = i18n.language;
        window.speechSynthesis.speak(utterance);
      });
    } else {
      alert('Sorry, your browser does not support speech synthesis.');
    }
  };

  useEffect(() => {
    if (prevAnswer) {
      if (current?.quiz?.showCorrections) {
        setShowCorrection(true);
      }
      reset(prevAnswer);
    }
  }, [prevAnswer]);

  useEffect(() => {
    if (settings.isSound) {
      speakText();
    } else if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();
    }
  }, [settings?.isSound, index]);

  return (
    <div
      className={styles.container}
    >
      {question?.pictures?.length > 0 && <Pictures pictures={question?.pictures} />}
      {picturesPairing && <div className={styles['bg-pictures-pairing']} />}
      {isPairing && <div className={styles['bg-pairing']} />}
      <div className={`
        ${styles.question}
        ${question?.pictures?.length === 0 || picturesPairing ? '' : styles.withPictures}`}>
        <div className={styles['senctence-block']}>
          <div className={styles.nav}>
            {index > 0 && quiz.allowPrevious
              && <button
                type="button"
                onClick={() => handleQuestionIndex(index - 1)}
              >
                <HiChevronLeft />
              </button>
            }
          </div>
          <p
            className={`${styles.sentence} ${styles[settings.font]} ${isPairing ? styles.pairing : ''} ${picturesPairing ? styles['picture-pairing'] : ''}`}
            dangerouslySetInnerHTML={{ __html: question.text }}
          />
          <div className={styles.nav}>
            {prevAnswer && quiz.allowPrevious
              && <button
                type="button"
                onClick={() => handleQuestionIndex(index + 1)}
              >
                <HiChevronRight />
              </button>
            }
          </div>
        </div>
        {question.inputs.length > 0 && <InputsOptions
            inputs={question.inputs}
            answerType={question.answerType}
            prevAnswer={prevAnswer}
            correction={showCorrection && prevAnswer?.correctAnswers}
            control={control}
            disabled={prevAnswer}
          />
        }
        {isSelect && question.options.length > 0 && <SelectsOptions
            options={question.options}
            selectedOptions={selectedOptions}
            disabled={prevAnswer}
            correction={showCorrection && prevAnswer?.correctAnswers}
            answerType={question.answerType}
            handleChange={(value) => setValue('selectedOptions', value)}
          />
        }
        {!isSelect && question.options.length > 0 && <Options
            options={question.options}
            selectedOptions={selectedOptions}
            disabled={prevAnswer}
            correction={showCorrection && prevAnswer?.correctAnswers}
            answerType={question.answerType}
            handleChange={(value) => setValue('selectedOptions', value)}
          />
        }
        {!picturesPairing && question.pairs.length > 0 && <PairsOptions
            pairs={[...question.pairs]}
            selectedPairs={selectedPairs}
            disabled={prevAnswer}
            questionType={question.type}
            answerType={question.answerType}
            prevAnswer={prevAnswer}
            showResponse={showResponse}
            correction={showCorrection && prevAnswer?.correctAnswers}
            handleChange={(value) => setValue('selectedPairs', value)}
          />
        }
        {picturesPairing && question.pairs.length > 0 && <AssociatePicturesOptions
            pairs={[...question.pairs]}
            disabled={prevAnswer}
            prevAnswer={prevAnswer}
            questionType={question.type}
            answerType={question.answerType}
            showResponse={showResponse}
            correction={showCorrection && prevAnswer?.correctAnswers}
            handleChange={(value) => setValue('selectedPairs', value)}
          />
        }
      </div>
      <Portal>
        <>
          <SettingsDesktop />
          <ZoomMobile
            question={question}
          />
          {!settings?.isZoom
          && <div className={`${styles.actions} ${submitDisabled ? styles.disabled : ''} ${prevAnswer ? styles.submited : ''}`}>
            <div className={styles.nav}>
              {index > 0 && quiz.allowPrevious
                && <button
                  type="button"
                  onClick={() => handleQuestionIndex(index - 1)}
                >
                  <HiChevronLeft />
                </button>
              }
            </div>
            <button
              type="button"
              className={`${styles.submit} ${getCorrection?.className} ${showResponseActive ? styles.showResponse : ''}`}
              onMouseEnter={() => setSubmitIsHover(true)}
              onMouseLeave={() => setSubmitIsHover(false)}
              onClick={handleSubmit(onSubmit)}
            >
              <Stars isHover={submitIsHover} />
              {prevAnswer && !showCorrection && <><FaCheck /><span>{t('current.submitted', { ns: 'quiz' })}</span></>}
              {!prevAnswer && t('btn.valid')}
              {prevAnswer && showCorrection && !showResponseActive && getCorrection?.label}
              {prevAnswer && showCorrection && showResponseActive && t('current.showResponse', { ns: 'quiz' })}
            </button>
            <div className={styles.nav}>
              {prevAnswer && answers?.entries?.length > index && quiz.allowPrevious
                && <button
                  type="button"
                  onClick={() => handleQuestionIndex(index + 1)}
                >
                  <HiChevronRight />
                </button>
              }
            </div>
          </div>
        }
        </>
      </Portal>
    </div>
  );
}
