import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';
import { IoAdd } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetFormationById, useGetFormationsList, useUpdateFormation } from '../../../hooks/formations';
import useTagsToOptionsLang, { ITag } from '../../../hooks/useTagsToOptionsLang';
import { IUser } from '../../../types/users';

import FilterSearchParam from '../../FilterSearchParam';
import Tags from '../../Tags';
import styles from './FormationsUsersModal.module.scss';

export default function FormationsUsersModal({
  close,
}:{
  close: () => void,
}) {
  const { t } = useTranslation('formations');
  const { user } = useSelector((d: any) => d.authReducer);
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams();
  const {
    data: formation,
  } = useGetFormationById(id);

  const { data: list } = useGetFormationsList();

  const {
    mutateAsync: updateFormation,
  } = useUpdateFormation();
  const users = list?.users || [];
  const tags = useTagsToOptionsLang(list?.tags || []);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const group = searchParams.get('user.group');
  const tag = searchParams.get('user.tag');
  const learners = formation?.learners?.map((l: IUser) => l._id) || [];

  const usersFiltered = useMemo(() => users.filter((u: IUser) => {
    if (learners.find((l: string) => l === u._id)) {
      return null;
    }
    if (group && !(u.groups as any)?.find(
      (o: string) => o === group,
    )) {
      return null;
    }
    if (!(u.groups as any)?.find(
      (o: string) => user?.groups?.find((g: string) => g === o),
    )) {
      return null;
    }
    if (tag && !(u.tags as any)?.find(
      (o: ITag) => o._id === tag,
    )) {
      return null;
    }
    return u;
  }), [users, group, tag]);

  function handleSelectUser(userId: string) {
    let usersSelected : string[] = [...selectedUsers];
    if (usersSelected.find((u: string) => u === userId)) {
      usersSelected = usersSelected.filter((u: string) => u !== userId);
    } else {
      usersSelected.push(userId);
    }
    setSelectedUsers(usersSelected);
  }

  async function handleAddLearners() {
    const learnersUpdated : any = [...selectedUsers, ...learners];
    await updateFormation({
      _id: id,
      learners: learnersUpdated,
    });
    await close();
    searchParams.delete('user.group');
    searchParams.delete('user.tag');
    setSearchParams(searchParams);
  }
  return (
    <div className={styles.container}>
      <header>
        <h2>{t('formation.learners.add', { ns: 'formations' })}</h2>
      </header>
      <div className={styles.filters}>
        <FilterSearchParam
          label={t('filters.groups', { ns: 'formations' })}
          name='user.group'
          options={user.groups.map(((g:string) => ({ value: g, label: g }))) || []}
        />
        <FilterSearchParam
          label={t('filters.tags', { ns: 'formations' })}
          name='user.tag'
          options={tags || []}
        />
      </div>
      <div className={styles.row}>

      </div>
      <div className={`${styles.row} ${styles.label}`}>
        <div className={styles.col}>
        </div>
        <div className={styles.col}>
          <p>{t('formation.label.learner')}</p>
        </div>
        <div className={styles.col}>
          <p>{t('filters.tags', { ns: 'formations' })}</p>
        </div>
      </div>
      <div className={styles.list}>
        {usersFiltered.map((u: IUser) => (
          <div key={u._id} className={styles.row}>
            <div className={styles.col}>
              <div className={styles.check}
                onClick={() => handleSelectUser(u._id)}
              >
                {selectedUsers.find((s: string) => s === u._id) && <FaCheck />}
              </div>
            </div>
            <div className={styles.col}>
              <p>{u.profile.firstName} {u.profile.lastName}</p>
            </div>
            <div className={styles.col}>
              <Tags tags={u.tags}/>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.action}>
        <button
          type="button"
          className={`${styles.btn} ${styles.add}`}
          onClick={() => handleAddLearners()}
        >
          <IoAdd />
          {t('formation.learners.modal.add', { ns: 'formations' })}
        </button>
      </div>
    </div>
  );
}
