import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';

import styles from './settings.module.scss';

import closeIcon from './images/close.svg';

import { SET_USER_QUIZ_SETTINGS } from '../../../actions/types';

export const fontsSize = ['xl', 'md', 'xs'];

const FontIcon = ({ color }: { color: string }) => (
  <svg width="28" height="17" viewBox="0 0 28 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.142623 16L7.27062 0.489999H10.0206L17.1486 16H13.7166L11.8686 11.688L13.2326 12.634H4.05862L5.42262 11.688L3.59662 16H0.142623ZM8.61262 4.12L5.79662 10.83L5.20262 9.972H12.0886L11.4946 10.83L8.65662 4.12H8.61262ZM21.8551 16.242C21.0631 16.242 20.3517 16.088 19.7211 15.78C19.0904 15.472 18.5991 15.054 18.2471 14.526C17.8951 13.998 17.7191 13.404 17.7191 12.744C17.7191 11.952 17.9244 11.3287 18.3351 10.874C18.7457 10.4047 19.4131 10.0747 20.3371 9.884C21.2611 9.67867 22.4857 9.576 24.0111 9.576H25.1771V11.292H24.0331C23.4611 11.292 22.9771 11.314 22.5811 11.358C22.1997 11.402 21.8844 11.4827 21.6351 11.6C21.3857 11.7027 21.2024 11.8347 21.0851 11.996C20.9824 12.1573 20.9311 12.3627 20.9311 12.612C20.9311 13.0227 21.0704 13.36 21.3491 13.624C21.6424 13.888 22.0604 14.02 22.6031 14.02C23.0284 14.02 23.4024 13.9247 23.7251 13.734C24.0624 13.5287 24.3264 13.2573 24.5171 12.92C24.7077 12.568 24.8031 12.172 24.8031 11.732V9.202C24.8031 8.55667 24.6564 8.102 24.3631 7.838C24.0697 7.55933 23.5711 7.42 22.8671 7.42C22.2804 7.42 21.6571 7.51533 20.9971 7.706C20.3517 7.882 19.7064 8.15333 19.0611 8.52L18.1591 6.298C18.5404 6.034 19.0024 5.80667 19.5451 5.616C20.1024 5.41067 20.6817 5.25667 21.2831 5.154C21.8844 5.03667 22.4564 4.978 22.9991 4.978C24.1284 4.978 25.0524 5.14667 25.7711 5.484C26.5044 5.80667 27.0544 6.31267 27.4211 7.002C27.7877 7.67667 27.9711 8.54933 27.9711 9.62V16H24.8691V13.822H25.0231C24.9351 14.3207 24.7444 14.7533 24.4511 15.12C24.1724 15.472 23.8131 15.7507 23.3731 15.956C22.9331 16.1467 22.4271 16.242 21.8551 16.242Z" fill={color}/>
  </svg>
);

const FontDesktopIcon = ({ color }: { color: string }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.3342 8.00002L25.3342 2.66669L21.3342 8.00002H24.0008V13.3334H21.3342L25.3342 18.6667L29.3342 13.3334H26.6675V8.00002H29.3342ZM12.4102 5.33335L4.41016 26.6667H7.25949L9.75949 20H18.2435L20.7435 26.6667H23.5928L15.5928 5.33335H12.4102ZM10.7582 17.3334L14.0008 8.68669L17.2435 17.3334H10.7582Z" fill={color}/>
  </svg>
);

const SoundIcon = ({ color }: { color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M12.0002 6.66667C13.4147 6.66667 14.7712 7.22857 15.7714 8.22877C16.7716 9.22896 17.3335 10.5855 17.3335 12C17.3335 13.4145 16.7716 14.771 15.7714 15.7712C14.7712 16.7714 13.4147 17.3333 12.0002 17.3333C10.5857 17.3333 9.22912 16.7714 8.22893 15.7712C7.22873 14.771 6.66683 13.4145 6.66683 12C6.66683 10.5855 7.22873 9.22896 8.22893 8.22877C9.22912 7.22857 10.5857 6.66667 12.0002 6.66667ZM12.0002 20C15.5602 20 22.6668 21.7867 22.6668 25.3333V28H1.3335V25.3333C1.3335 21.7867 8.44016 20 12.0002 20ZM22.3468 7.14667C25.0402 10.08 25.0402 14.1467 22.3468 16.84L20.1068 14.5867C21.2268 13.0133 21.2268 10.9733 20.1068 9.4L22.3468 7.14667ZM26.7602 2.66667C32.0002 8.06667 31.9602 16.1467 26.7602 21.3333L24.5868 19.16C28.2802 14.92 28.2802 8.86667 24.5868 4.84L26.7602 2.66667Z" fill={color}/>
  </svg>
);

const ZoomIcon = ({ color }: { color: string }) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5683 18.7747C14.4566 20.3305 11.835 21.0309 9.22875 20.7355C6.62252 20.4401 4.22415 19.1707 2.51412 17.1817C0.804097 15.1927 -0.0912825 12.6309 0.00736432 10.0096C0.106011 7.38837 1.1914 4.90117 3.04608 3.04633C4.90076 1.1915 7.38775 0.10602 10.0088 0.00736493C12.6299 -0.0912901 15.1914 0.804164 17.1803 2.51433C19.1691 4.2245 20.4384 6.62307 20.7337 9.22952C21.0291 11.836 20.3288 14.4578 18.7731 16.5697L24.5035 22.2985C24.6568 22.4413 24.7797 22.6135 24.865 22.8049C24.9502 22.9963 24.9961 23.2029 24.9998 23.4124C25.0035 23.6218 24.9649 23.8299 24.8865 24.0242C24.808 24.2184 24.6912 24.3949 24.5431 24.5431C24.395 24.6912 24.2185 24.808 24.0243 24.8865C23.83 24.9649 23.622 25.0035 23.4125 24.9998C23.203 24.9961 22.9965 24.9502 22.8051 24.8649C22.6137 24.7797 22.4415 24.6567 22.2987 24.5034L16.5683 18.7747ZM17.6811 10.3999C17.6811 12.3309 16.9141 14.1827 15.5489 15.5481C14.1836 16.9135 12.3319 17.6805 10.4012 17.6805C8.4704 17.6805 6.61871 16.9135 5.25345 15.5481C3.8882 14.1827 3.1212 12.3309 3.1212 10.3999C3.1212 8.46902 3.8882 6.61718 5.25345 5.25181C6.61871 3.88644 8.4704 3.11938 10.4012 3.11938C12.3319 3.11938 14.1836 3.88644 15.5489 5.25181C16.9141 6.61718 17.6811 8.46902 17.6811 10.3999Z" fill={color}/>
  </svg>
);

export const SettingsDesktop = () => {
  const { settings } = useSelector((s: any) => s.userQuizReducer);
  const [isFonts, setIsFonts] = useState(false);
  const dispatch = useDispatch();

  function handleChangeSettings(obj: any) {
    dispatch({
      type: SET_USER_QUIZ_SETTINGS,
      payload: {
        ...settings,
        ...obj,
      },
    });
  }

  return (
    <div className={styles['settings-desktop']}>
       <div className={`${styles.options} ${!isFonts ? styles.active : ''}`}>
          <button
            type='button'
            className={settings.isZoom ? styles.active : ''}
            onClick={() => handleChangeSettings({ isZoom: !settings.isZoom })}
          >
            <ZoomIcon color={settings.isZoom ? '#419FB4' : '#949494'}/>
          </button>
          <button
            type='button'
            className={settings.isSound ? styles.active : ''}
            onClick={() => handleChangeSettings({ isSound: !settings.isSound })}
          >
            <SoundIcon color={settings.isSound ? '#4C549E' : '#949494'}/>
          </button>
          <button
            type='button'
            onClick={() => setIsFonts(true)}
          >
            <FontDesktopIcon color={'#949494'}/>
          </button>
        </div>
        <div className={`${styles.options} ${styles.font} ${isFonts ? styles.active : ''}`}>

          {fontsSize.map((s: string) => <button
            key={s}
            type='button'
            className={`${s === settings.font ? styles.active : ''} ${styles[s]}`}
            onClick={() => handleChangeSettings({ font: s })}
          >
            <FontIcon color={s === settings.font ? '#4C549E' : '#949494'}/>
          </button>)}
          <div className={styles.close}
            onClick={() => setIsFonts(false)}
          >
            <img src={closeIcon} alt='close' />
          </div>
        </div>
    </div>
  );
};

export const SettingsMobile = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation('quiz');
  const ref = useRef<HTMLDivElement>(null);

  const { settings } = useSelector((s: any) => s.userQuizReducer);
  const dispatch = useDispatch();

  function handleChangeSettings(obj: any) {
    dispatch({
      type: SET_USER_QUIZ_SETTINGS,
      payload: {
        ...settings,
        ...obj,
      },
    });
  }

  // useEffect(() => {
  //   const handleClickOutside = (event :any) => {
  //     console.log(event.target.id === 'settings-toggle');
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       close();
  //     }
  //     if (event.target.id === 'settings-toggle') {
  //       close();
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [ref]);

  return (
    <div ref={ref} className={`${styles['settings-mobile']} ${isOpen ? styles.active : ''}`}>
    <h4>{t('current.settings.title')}</h4>
    <label>{t('current.settings.label.font')}</label>
    <div className={styles.fonts}>
      {fontsSize.map((s: string) => <button
        key={s}
        type='button'
        className={`${s === settings.font ? styles.active : ''} ${styles[s]}`}
        onClick={() => handleChangeSettings({ font: s })}
      >
        <FontIcon color={s === settings.font ? '#4C549E' : '#949494'}/>
      </button>)}
    </div>
    <label>{t('current.settings.label.sound')}</label>
    <div className={styles.sound}
      onClick={() => handleChangeSettings({ isSound: !settings.isSound })}
    >
      <div className={styles.check}>
        {settings.isSound && <FaCheck />}
      </div>
      <p>{t('current.settings.sound')}</p>
    </div>
  </div>
  );
};
