import React from 'react';
import { useTranslation } from 'react-i18next';
import plantIcon from './images/plants.svg';
import quizIcon from './images/quiz.svg';
import styles from './dashboardCount.module.scss';

const DashboardCount = ({
  name,
  count,
}: {
  name: string,
  count: number
}) => {
  const { t } = useTranslation('global');
  const labels = [
    {
      key: 'plant',
      label: t('dashboard.count.plants'),
      icon: plantIcon,
      color: '#dbeee7',
    },
    {
      key: 'user',
      label: t('dashboard.count.user'),
      icon: plantIcon,
      color: '#dbeee7',
    },
    {
      key: 'quiz',
      label: t('dashboard.count.quiz'),
      icon: quizIcon,
      color: '#fff0ec',
    },
  ];

  const label = labels.find((l: any) => l.key === name);
  return (<div className={styles.counter}>
    {label
      && <div className={styles.title}>
        <div className={styles.icon}
          style={{ backgroundColor: label.color }}
        >
          <img src={label.icon} alt={label.label} />
        </div>
        <p>{label.label}</p>
      </div>
    }
      <h2>{count}</h2>
    </div>
  );
};

export default DashboardCount;
