import axios from 'axios';
import { IUser } from '../types/users';

const getUser = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.user;
};

const getUsersList = async (params?: { organisations?: string[] }) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/list`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.list;
};

// eslint-disable-next-line max-len
const createUser = async (data: Partial<IUser>): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/users`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

// eslint-disable-next-line max-len
const updateUser = async (data: Partial<any>): Promise<{ user: IUser }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/users/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  getUser,
  getUsersList,
  updateUser,
  createUser,
};
