import React, { CSSProperties } from 'react';
import { IoClose } from 'react-icons/io5';
import styles from './tag.module.scss';

interface customCSS extends CSSProperties {
  '--color': string;
}

const Tag = (
  { text, color, handleDelete } : { text: string, color?: string, handleDelete?: () => void },
) => (
  <div className={styles.tag} style={{ '--color': color } as customCSS}>
    <p>{typeof text === 'string' ? text : ''}</p>
    {typeof handleDelete === 'function' && (
      <div className={styles.delete} onClick={() => handleDelete()}><IoClose /></div>
    )}
  </div>
);

export default Tag;
