/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {
  createFormation, deleteFormation, duplicateFormation, getFormation, getFormationsList, getUserFormations, updateFormation,
} from '../../api/formations';
import { IFormation } from '../../types/formations';

const useGetFormationById = (id?: string) => useQuery({
  queryKey: ['Formation', id],
  queryFn: async (): Promise<IFormation | null | undefined> => getFormation(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useGetFormations = () => useQuery({
  queryKey: ['Formations'],
  queryFn: async (): Promise<any | null> => getUserFormations(),
  keepPreviousData: true,
});

const useGetFormationsList = () => useQuery({
  queryKey: ['FormationsList'],
  queryFn: async (): Promise<any | null> => getFormationsList(),
  keepPreviousData: true,
});

const useCreateFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IFormation>) => createFormation(data),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Formation'] });
      queryClient.invalidateQueries({ queryKey: ['Formations'] });
    },
  });
};

const useUpdateFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: Partial<IFormation>) => updateFormation(data),
    onSuccess: async ({ formation }) => {
      queryClient.invalidateQueries({ queryKey: ['Formation'] });
      queryClient.invalidateQueries({ queryKey: ['Formations'] });
      queryClient.setQueryData(['Formation', formation._id], formation);
    },
  });
};

const useDuplicateFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => duplicateFormation(id),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['Formations'] });
    },
  });
};

const useDeleteFormation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: string) => deleteFormation(id),
    onSuccess: async (_: any, variables: any) => {
      queryClient.invalidateQueries({ queryKey: ['Formations'] });
      queryClient.setQueryData(['Formation', variables], null);
    },
  });
};

export {
  useGetFormationsList,
  useGetFormationById,
  useGetFormations,
  useCreateFormation,
  useUpdateFormation,
  useDuplicateFormation,
  useDeleteFormation,
};
