import React, { useState, useEffect } from 'react';
import styles from './Timer.module.scss';

interface TimerProps {
  duration: number;
}

const Timer: React.FC<TimerProps> = ({ duration }) => {
  const [minutes, setMinutes] = useState(Math.floor(duration));
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds === 0 && minutes === 0) {
        clearInterval(countdown);
      } else if (seconds === 0) {
        setMinutes((prev) => prev - 1);
        setSeconds(59);
      } else {
        setSeconds((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [seconds, minutes]);

  const formatTime = (time: number) => (time < 10 ? ` 0${time} ` : ` ${time} `);

  return (
    <div className={styles.timer}>
      <span className={`${styles.animated} ${styles.minutes}`}>{formatTime(minutes)}</span>:
      <span className={`${styles.animated} ${styles.seconds}`}>{formatTime(seconds)}</span>
    </div>
  );
};

export default Timer;
