import React, { useRef, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import filterIcon from '../../../assets/images/filter.svg';
import styles from './userListMobile.module.scss';
import { IUser } from '../../../types/users';
import InputSearch from '../../InputSearch';
import FilterSearchParam from '../../FilterSearchParam';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { IOption } from '../../../types/options';

const Card = ({ user, role }: { user: IUser, role?: string }) => {
  const tags = useTagsToOptionsLang(user?.tags || []);
  const tag = tags.length > 0 ? tags[0] : null;
  const group = user.groups.length > 0 ? user.groups[0] : null;
  return (<Link to={`/users/${user._id}`}
      className={styles.card}
    >
    <div className={styles.row}>
      <h4><span>{user.profile?.lastName}</span> {user.profile?.firstName}</h4>
      {role && <p className={styles.role}>{role}</p>}
    </div>
    <div className={styles.row}>
      <div className={styles.tags}>
        {tag
          && <>
            <p key={tag.value}
              style={{ backgroundColor: tag.color }}
              >
              {tag.label}
            </p>
            {tags.length > 1
              && <p
              style={{ backgroundColor: tag.color }}
              >
              + {tags.length - 1}
            </p>
            }
          </>
        }
      </div>
      <div className={styles.tags}>
        {group
          && <>
            <p
              style={{ backgroundColor: '#abb0e4' }}
              >
              {group}
            </p>
            {user.groups.length > 1
              && <p
              style={{ backgroundColor: '#abb0e4' }}
              >
              + {user.groups.length - 1}
            </p>
            }
          </>
        }
      </div>
    </div>
  </Link>);
};

const UserListMobile = ({
  users,
  tags,
  groups,
  roles,
}: {
  users: IUser[],
  tags: IOption[],
  groups: IOption[],
  roles: IOption[]
}) => {
  const { t } = useTranslation('users');
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector((store: any) => store.authReducer);

  const { search = '', tag = '' } = Object.fromEntries(searchParams);
  const filtersRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(!!search || !!tag);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 1) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  return (
    <div className={styles.mobile}>
    <div
      className={styles.filters}
      ref={filtersRef}
    >
      <div className={styles.btns}>
        {user.role === 'Owner'
          ? <Link to={'/users/create'}
            className={`${styles.btn} ${styles.add}`}
          >
            <IoIosAdd />{t('user.new')}
          </Link>
          : <div/>
        }
        <button
          className={styles.toggle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={filterIcon} alt="filter" />
        </button>
      </div>
      {isOpen
        && <div className={styles.panel}>
          <div className={styles.search}>
            <InputSearch
              defaultValue={search}
              handleSearch={handleSearch}
            />
          </div>
          <div className={styles['filters-row']}>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('filters.tags')}
                name='tag'
                options={tags || []}
              />
            </div>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('filters.groups')}
                name='group'
                options={groups || []}
              />
            </div>
          </div>
        </div>
      }
    </div>
    <div className={styles.list}
      style={isOpen ? {
        height: `calc(100% - ${filtersRef.current?.getBoundingClientRect().height}px)`,
      } : {
        height: 'calc(100% - 83px)',
      }}
    >
      {users.map((u :IUser) => (<Card
          key={u._id}
          user={u}
          role={roles.find((r: IOption) => r.value === u.role)?.label}
      />))}
    </div>
  </div>
  );
};

export default UserListMobile;
