import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { Controller, Control } from 'react-hook-form';
import styles from '../styled-select.module.scss';
import {
  styleBase,
  styleBold,
} from '../style';

type OptionType = {
  value: string
  label: string | null
};

interface SelectProps {
  name: string,
  control: Control<any>,
  options: OptionType[],
  rules?: any,
  widthLabel?: string | null,
  inline?: boolean,
  placeholder?: string,
  isMulti?: boolean,
  label?: string | null,
  className?: string,
  isClearable?: boolean,
  isSearchable?: boolean,
  defaultValue?: any,
  formatCreateLabel?: string,
  handleChange?: (val: any) => void,
  prop?: any,
}

const StyledSelect = ({
  name,
  control,
  rules,
  options,
  widthLabel = null,
  inline = false,
  placeholder = '',
  label = null,
  className,
  isClearable = false,
  isSearchable = false,
  defaultValue,
  formatCreateLabel,
  handleChange,
  ...props
}: SelectProps) => {
  function getClassName() {
    let style;
    if (className === 'bold') style = styleBold;
    else style = styleBase;
    return style;
  }
  const customCreateLabel = (inputValue: string) => `${formatCreateLabel} : "${inputValue}"`;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field,
      }) => (
        <div className={inline ? `${styles['container-select']} ${styles.inline}` : `${styles['container-select']}`}>
          {label && <label style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</label>}
          <CreatableSelect
            {...field}
            {...props}
            formatCreateLabel={customCreateLabel}
            value={field.value
              ? options.find((option: OptionType) => option.value === field.value)
              : null}
            options={options}
            onChange={(opt: OptionType) => {
              field.onChange(props.isMulti ? opt : opt?.value);
              if (handleChange) handleChange(opt);
            }}
            defaultValue={defaultValue}
            isClearable={isClearable}
            isSearchable={isSearchable}
            className={`${styles.select}`}
            placeholder={placeholder}
            styles={getClassName()}
          />
        </div>
      )}
    />
  );
};

export default StyledSelect;
