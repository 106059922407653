import { Dispatch } from 'redux';
import { getData, postData, putData } from '.';

import {
  GET_CURRENT_QUIZ, POST_ANSWER, START_QUIZ, USER_QUIZZ_ERROR, USER_QUIZZ_LOADING,
} from './types';

// export const getUserQuizzListAction = async (dispatch: Dispatch, filters: UserQuizzFilters) => {
//   const lang = localStorage.getItem('lang') || 'fr';
//   let url = `/quiz?sortKey=name&order=asc&type=private&lang=${lang}`;
//   if (filters.search) {
//     url += `&search=${filters.search}`;
//   }
//   dispatch({
//     type: USER_QUIZZ_LOADING,
//     payload: GET_USER_QUIZZES,
//   });
//   const response = await getData(USER_QUIZZ_ERROR, url, dispatch, true);
//   if (response.data.quiz) {
//     dispatch({
//       type: GET_USER_QUIZZES,
//       payload: response.data,
//     });
//   } else {
//     dispatch({
//       type: GET_USER_QUIZZES,
//       payload: {
//         meta: {
//           tolal: 0,
//         },
//       },
//     });
//   }
// };

export const getCurrentQuizAction = async (
  dispatch: Dispatch,
  id: string,
) => {
  const url = `/quiz/${id}`;
  dispatch({
    type: USER_QUIZZ_LOADING,
    payload: GET_CURRENT_QUIZ,
  });
  const response = await getData(USER_QUIZZ_ERROR, url, dispatch, true);
  if (response.data?.quiz) {
    dispatch({
      type: GET_CURRENT_QUIZ,
      payload: response.data.quiz,
    });
  }
};

export const startQuizzAction = async (
  dispatch: Dispatch,
  id: string,
  formation: string | null = null,
) => {
  const url = `/answers/${id}${formation ? `?formationId=${formation}` : ''}`;
  dispatch({
    type: USER_QUIZZ_LOADING,
    payload: START_QUIZ,
  });
  const response = await postData(USER_QUIZZ_ERROR, url, dispatch, {}, true);
  if (response?.data?.answers) {
    dispatch({
      type: START_QUIZ,
      payload: response.data.answers,
    });
  }
};

export const postAnswerAction = async (dispatch: Dispatch, quizId: string, data: any) => {
  const url = `/answers/push/${quizId}`;
  // await dispatch({
  //   type: USER_QUIZZ_LOADING,
  //   payload: POST_ANSWER,
  // });
  const response = await putData(USER_QUIZZ_ERROR, url, dispatch, data, true);
  if (response?.data?.answers) {
    await dispatch({
      type: POST_ANSWER,
      payload: {
        ...response.data,
        question: data.question,
      },
    });
  }
  return response;
};
