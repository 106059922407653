import React, { useRef, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import filterIcon from '../../../assets/images/filter.svg';
import styles from './plantsListsMobile.module.scss';
import InputSearch from '../../InputSearch';
import FilterSearchParam from '../../FilterSearchParam';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { IOption } from '../../../types/options';
import { IPlantsList } from '../../../types/plantsList';

const Card = ({ plantsList }: { plantsList: IPlantsList }) => {
  const tags = useTagsToOptionsLang(plantsList?.tags || []);
  const { t } = useTranslation('plantsLists');
  const tag = tags.length > 0 ? tags[0] : null;

  return (<Link to={`/plantsList/${plantsList._id}`}
      className={styles.card}
    >
    <div className={styles.row}>
      <h4>{plantsList.name}</h4>
      <p>{plantsList.plants.length} {t('plantsList.label.plants')}</p>
    </div>
    <div className={styles.row}>
      <div className={styles.tags}>
        {tag
          && <>
            <p key={tag.value}
              style={{ backgroundColor: tag.color }}
              >
              {tag.label}
            </p>
            {tags.length > 1
              && <p
              style={{ backgroundColor: tag.color }}
              >
              + {tags.length - 1}
            </p>
            }
          </>
        }
      </div>
    </div>
  </Link>);
};

const PlantsListsMobile = ({
  plantsLists,
  tags,
}: {
  plantsLists: IPlantsList[],
  tags: IOption[],
}) => {
  const { t } = useTranslation('plantsLists');
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', tag = '' } = Object.fromEntries(searchParams);
  const filtersRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(!!search || !!tag);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 1) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  return (
    <div className={styles.mobile}>
    <div
      className={styles.filters}
      ref={filtersRef}
    >
      <div className={styles.btns}>
        <Link to={'/users/create'}
          className={`${styles.btn} ${styles.add}`}
        >
          <IoIosAdd />{t('plantsList.new')}
        </Link>
        <button
          className={styles.toggle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={filterIcon} alt="filter" />
        </button>
      </div>
      {isOpen
        && <div className={styles.panel}>
          <div className={styles.search}>
            <InputSearch
              defaultValue={search}
              handleSearch={handleSearch}
            />
          </div>
          <div className={styles['filters-row']}>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('filters.tags')}
                name='tag'
                options={tags || []}
              />
            </div>
          </div>
        </div>
      }
    </div>
    <div className={styles.list}
      style={isOpen ? {
        height: `calc(100% - ${filtersRef.current?.getBoundingClientRect().height}px)`,
      } : {
        height: 'calc(100% - 83px)',
      }}
    >
      {plantsLists.map((p :IPlantsList) => (<Card
          key={p._id}
          plantsList={p}
      />))}
    </div>
  </div>
  );
};

export default PlantsListsMobile;
