/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQuery,
} from '@tanstack/react-query';
import getUserQuiz from '../../api/quiz';

const useGetUserQuiz = () => useQuery({
  queryKey: ['UserQuiz'],
  queryFn: async (): Promise<any | null> => getUserQuiz(),
  keepPreviousData: true,
  refetchInterval: 300000,
});

export {
  useGetUserQuiz,
};
