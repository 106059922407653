import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HeaderMobile from '../../components/HeaderMobile';
import styles from './users.module.scss';
import { useGetOrganisation, useGetOrganisationUsers } from '../../hooks/organisations';
import { IUser } from '../../types/users';
import useTagsToOptionsLang from '../../hooks/useTagsToOptionsLang';
import useOptionsLang from '../../hooks/useOptionsLang';
import UserListMobile from '../../components/users/userListMobile';
import UserListDesktop from '../../components/users/userListDesktop';
import { useGetUsersList } from '../../hooks/users';
import { IOption } from '../../types/options';

const Users = () => {
  const { user: userAuth } = useSelector((d: any) => d.authReducer);
  const { t } = useTranslation('users');
  const [searchParams] = useSearchParams();
  const {
    search = '', tag = '', sortKey = '', order = 'desc', group = '',
  } = Object.fromEntries(searchParams);
  const { user } = useSelector((d: any) => d.authReducer);
  const organisationId = localStorage.getItem('organisation');
  const { data: organisation } = useGetOrganisation(organisationId || '');
  const { data: users } = useGetOrganisationUsers(organisationId || '');
  const {
    data: list = {},
  } = useGetUsersList(organisationId ? { organisations: [organisationId] } : {});

  const tags = useTagsToOptionsLang(organisation?.tags || []);
  const groups = list.groups || [];
  const roles = useOptionsLang(list.userRoles || []).filter((o:IOption) => o.value !== 'Admin' && o.value !== 'Owner');

  const filteredUsers = useMemo(() => {
    let usersList = users?.filter((u:IUser) => u._id !== user._id) || [];
    if (search) {
      usersList = usersList.filter((u:IUser) => {
        let isIn = false;
        if (u.profile.firstName.toLowerCase().includes(search.toLowerCase())) {
          isIn = true;
        }
        if (u.profile.lastName.toLowerCase().includes(search.toLowerCase())) {
          isIn = true;
        }
        return isIn ? u : null;
      });
    }
    if (tag) {
      usersList = usersList.filter((u:IUser) => {
        let isIn = false;
        if (u.tags?.find((ta: any) => ta._id === tag)) {
          isIn = true;
        }
        return isIn ? u : null;
      });
    }
    if (group) {
      usersList = usersList.filter((u:IUser) => {
        let isIn = false;
        if (u.groups?.find((g: string) => g === group)) {
          isIn = true;
        }
        return isIn ? u : null;
      });
    }
    if (sortKey) {
      if (sortKey === 'createdAt') {
        usersList = usersList.sort(
          (a: IUser, b: IUser) => (new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1),
        );
      }
      if (order === 'asc') {
        usersList = usersList.reverse();
      }
    }
    return usersList;
  }, [users, search, tag, group, order, sortKey]);

  const title = `${filteredUsers?.length || 0} ${t('title.users')} `;
  return (
    <div className={styles.user}>
      <HeaderMobile title={title} />
      <div className={styles.page}>
        <UserListMobile
          users={filteredUsers}
          tags={tags}
          groups={groups}
          roles={userAuth.role === 'Owner' ? roles : []}
        />
         <UserListDesktop
          title={title}
          users={filteredUsers}
          tags={tags}
          groups={groups}
          roles={userAuth.role === 'Owner' ? roles : []}
        />
      </div>
    </div>
  );
};

export default Users;
