/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { PairOption } from '../../../types/userQuiz';
import { getRandomRotation, getTepicPicture } from '../../../utils';
import styles from './PairsOptions.module.scss';

const PairItem = ({
  item,
  isDragging = false,
  isDraggedItem = false,

  response,
  questionType,
}:{
  item : any,
  isDragging?: boolean,
  isDraggedItem?: boolean,
  response?: PairOption
  questionType: string,
}) => {
  if (response || isDragging) {
    return (<div className={`${styles.option} ${questionType ? styles[questionType] : ''} ${styles.empty} ${item?.pictures?.length === 0 && item?.text ? styles.text : ''} ${item?.pictures?.length > 0 ? styles.pictures : ''}`}
      style={{ transform: `rotate(${item.rotation}deg)` }}
    >
    </div>);
  }
  return (
    <div className={`${styles.option} ${isDraggedItem ? styles.dragging : ''} ${item?.pictures?.length === 0 && item?.text ? styles.text : ''}`}
      style={isDraggedItem ? {} : { transform: `rotate(${item.rotation}deg)` }}
    >
      {item?.text && <p>{item.text}</p>}
      {item.pictures?.length > 0
         && <div
          className={`${styles['option-pictures']} ${styles[`row-${item.pictures.length}`]}`}
       >
         {item.pictures.map((p: any) => <img
           key={p.file_name}
           src={getTepicPicture(p)}
           alt={p.file_name}/>)}
       </div>
      }
    </div>
  );
};

const ResponseItem = ({
  item,
  response,
  isDraggingOver = false,
  questionType,
  isDragging,
  correctionStyle,
  handleResponseClick,
}:{
  item : PairOption,
  response?: PairOption,
  questionType?: string,
  isDraggingOver?: boolean,
  isDragging? : boolean,
  correctionStyle: string,
  handleResponseClick: (id: string, responseId?: string | null) => void
}) => (
  <div className={`${styles.option} ${isDragging ? styles.dragging : ''} ${styles.response} ${correctionStyle} ${questionType ? styles[questionType] : ''}  ${isDraggingOver ? styles.hover : ''}`}>
    {item?.text && <p>{item.text}</p>}
    <div className={`${styles.input} ${!response?.text && response?.pictures ? styles['pictures-active'] : ''} ${response ? styles.active : ''}`}
      onClick={() => handleResponseClick(item?._id, response?._id)}
    >
      {response?.text && <p>{response?.text}</p>}
      {response?.pictures
         && <div
          className={`${styles['option-pictures']} ${styles[`row-${response.pictures.length}`]}`}
       >
         {response.pictures.map((p: any) => <img
           key={p.file_name}
           src={getTepicPicture(p)}
           alt={p.file_name}/>)}
       </div>
      }
      {item.pictures && !response?.pictures
         && <div
          className={`${styles['option-pictures']} ${styles[`row-${item.pictures.length}`]}`}
       >
         {item.pictures.map((p: any) => <img
           key={p.file_name}
           src={getTepicPicture(p)}
           alt={p.file_name}/>)}
       </div>
      }
    </div>
  </div>
);

const PairsOptions = ({
  pairs,
  selectedPairs,
  handleChange,
  disabled = false,
  questionType,
  prevAnswer,
  correction,
  showResponse,
}:{
  pairs: any,
  answerType?: string,
  questionType?: any,
  selectedPairs: string[],
  disabled?: boolean,
  prevAnswer: any,
  correction: string[],
  showResponse?: boolean,
  handleChange: (value: any) => void
}) => {
  const [draggedItem, setDraggedItem] = useState<null | string>();
  const [listA, setListA] = useState<PairOption[]>([]);
  const listB: PairOption[] = [...pairs].filter((p: PairOption) => p.group === '2');

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    const style : any = {
      ...draggableStyle,
      transition: null,
      transform: isDragging ? draggableStyle.transform : null,
    };
    return style;
  };

  const getCorrectionStyle = (index: number) => {
    if (correction?.length
      && showResponse
      && correction?.length === prevAnswer?.selectedPairs?.length) {
      const pair = prevAnswer?.correctAnswers[index].split('.');
      const isCorrect = correction.find((c:string) => c === `${pair[0]}.${pair[1]}` || c === `${pair[1]}.${pair[0]}`);
      return isCorrect ? styles.correct : styles.wrong;
    }
    if (correction?.length && correction?.length === prevAnswer?.selectedPairs?.length) {
      const pair = prevAnswer.selectedPairs[index].split('.');
      const isCorrect = correction.find((c:string) => c === `${pair[0]}.${pair[1]}` || c === `${pair[1]}.${pair[0]}`);
      return isCorrect ? styles.correct : styles.wrong;
    }
    return '';
  };

  const handleDragStart = (e: any) => {
    setDraggedItem(e.draggableId);
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    setDraggedItem(null);
    if (!destination) {
      return;
    }
    const elementB = listB.find((b) => b._id === destination.droppableId);
    if (source.droppableId === 'listA' && elementB?._id) {
      const elementA = listA[source.index];
      const selected = [...selectedPairs.filter((s) => !s.includes(elementB._id))];
      handleChange([...selected, `${elementB._id}.${elementA._id}`]);
    }
  };

  async function handleResponseClick(id: string, responseId?: string | null) {
    let selected = [...selectedPairs.filter(
      (s) => !s.includes(id),
    )];
    if (responseId) {
      selected = [...selected.filter(
        (s) => !s.includes(`${id}.${responseId}`),
      )];
    }
    if (draggedItem) {
      selected = [...selected.filter(
        (s) => !s.includes(draggedItem),
      )];
      selected.push(`${id}.${draggedItem}`);
    }
    await handleChange(selected);
    setDraggedItem(null);
  }

  useEffect(() => {
    if (pairs) {
      const list: PairOption[] = [...pairs]
        .filter((p: PairOption) => p.group === '1')
        .map((p) => ({ ...p, rotation: getRandomRotation() }));
      setListA(list);
    }
  }, []);

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
    <DragDropContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Droppable droppableId="listA" isDropDisabled={true}>
        {(droppableProvided) => (
          <div
            ref={droppableProvided.innerRef}
            className={styles.list}
          >
            {listA.map((item :any, index: number) => (
              <Draggable
                key={item._id}
                draggableId={item._id}
                index={index}
                isDragDisabled={!!listB.find(
                  (r) => selectedPairs.find((s) => s.includes(`${r._id}.${item._id}`)),
                )}>
                {(provided, snapshot) => (
                  <>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() => setDraggedItem(item._id)}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <PairItem
                      item={item}
                      questionType={questionType}
                      isDraggedItem={draggedItem === item._id}
                      response={listB.find(
                        (r) => selectedPairs.find((s) => s.includes(`${r._id}.${item._id}`)),
                      )} />
                    </div>
                    {snapshot.isDragging && (
                      <div>
                        <PairItem
                          item={item}
                          questionType={questionType}
                          isDragging={snapshot.isDragging}
                          response={listB.find(
                            (r) => selectedPairs.find((s) => s.includes(`${r._id}.${item._id}`)),
                          )} />
                      </div>
                    )}

                  </>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder && null}

          </div>
        )}
      </Droppable>
      <div
        className={styles.list}
        >
        {listB.map((item, i) => (
          <Droppable key={item._id} droppableId={item._id}
          >
            {(provided, snapshot) => (
              <>
              <div
                ref={provided.innerRef}
                style={{
                  transform: '',
                }}
              >
                <ResponseItem
                  item={item}
                  isDraggingOver={snapshot.isDraggingOver}
                  isDragging={!!draggedItem}
                  questionType={questionType}
                  correctionStyle={getCorrectionStyle(i)}
                  response={showResponse
                    ? listA.find(
                      (r) => prevAnswer?.correctAnswers.find((s: string) => s.includes(`${item._id}.${r._id}`) || s.includes(`${r._id}.${item._id}`)),
                    )
                    : listA.find(
                      (r) => selectedPairs.find((s) => s.includes(`${item._id}.${r._id}`)),
                    )
                  }
                  handleResponseClick={(id, res) => handleResponseClick(id, res)}
                />
              </div>
              {provided.placeholder && null}
            </>
            )}
          </ Droppable>
        ))}
      </div>
    </DragDropContext>
    </div>
  );
};

export default PairsOptions;
