/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQuery,
} from '@tanstack/react-query';
import getDashboard from '../../api/dashboard';

import {
  IUser,
} from '../../types/users';

const useGetDashboard = () => useQuery({
  queryKey: ['Dashboard'],
  queryFn: async (): Promise<IUser | null | undefined> => getDashboard(),
  refetchInterval: 60000,
});

export default useGetDashboard;
