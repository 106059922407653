import axios from 'axios';

const getOrganisation = async (id: string | undefined) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/organisations/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.organisation;
};

const getOrganisationUsers = async (id: string | undefined) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/users/organisation/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.users;
};

export {
  getOrganisation,
  getOrganisationUsers,
};
