/* eslint-disable no-param-reassign */
import React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { IoChevronUp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styles from './formationsLearnersDesktop.module.scss';
import { IUser } from '../../../types/users';

const Row = ({
  learner,
}: {
  learner: IUser,
}) => (<div
      className={`${styles.row}`}
    >
    <div className={styles.col}>
      <p>{learner.profile?.firstName} {learner.profile?.lastName}</p>
    </div>
    <div className={styles.col}>
      {learner.groups.length > 0 && <p>{learner.groups[0]}</p>}
    </div>
    <div className={styles.col}>
    </div>
  </div>);

const FormationsLearnersDesktop = ({
  learners,
  title,
  openModal,
}: {
  learners: IUser[],
  title: string,
  openModal: () => void,
}) => {
  const { t } = useTranslation('formations');
  const [searchParams, setSearchParams] = useSearchParams();
  const { order = 'desc', sortKey = '' } = Object.fromEntries(searchParams);

  const handleSorting = (key: any) => {
    if (!key) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', key);
    params.set('order', order === 'asc' ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const labels = [
    {
      label: t('formation.label.learner'),
    },
    {
      label: t('formation.label.group'),
    },
  ];

  return (
      <div className={styles.desktop}>
      <div className={styles.header}>
        <h1>{title}</h1>
        <div className={styles.actions}>
          <button
            className={`${styles.btn} ${styles.add}`}
            type="button"
            onClick={() => openModal()}
          >
            <IoIosAdd />{t('formation.learners.add')}
          </button>
        </div>
      </div>
      {learners.length > 0
        && <>
        <div className={styles.content}>
          <div className={`${styles.row} ${styles.label}`}>
            {labels.map((l: any) => <div key={l.label}
              className={`${styles.col} ${l?.sortKey ? styles.sort : ''} ${order === 'desc' ? styles.desc : ''}`}
              onClick={() => handleSorting(l?.sortKey)}
            >
              {l?.sortKey === sortKey && <div className={styles.icon}>
                <IoChevronUp />
              </div>
              }
              <p>{l.label}</p>
            </div>)}
          </div>
          <div className={styles.list}
          >
            {learners.map((l: IUser) => (<Row
              key={l._id}
              learner={l}
            />))}
          </div>
        </div>
      </>
      }
    </div>
  );
};
export default FormationsLearnersDesktop;
