import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProfileAction } from '../../actions/auth';

import styles from './page404.module.scss';

const Page404 = ({ message }: { message?: string }) => {
  const { t } = useTranslation('global');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      getProfileAction(dispatch);
    }
  }, []);

  return (
    <div
      className={styles.container}
    >
      <p>{message || t('404')}</p>
    </div>
  );
};

export default Page404;
