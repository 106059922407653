/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQuery,
} from '@tanstack/react-query';
import { getOrganisation, getOrganisationUsers } from '../../api/organisation';
import {
  IOrganisation,
} from '../../types/organisations';
import { IUser } from '../../types/users';

const useGetOrganisation = (id?: string | undefined) => useQuery({
  queryKey: ['Organisation', id],
  queryFn: async (): Promise<IOrganisation | null | undefined> => getOrganisation(id),
  keepPreviousData: true,
  enabled: !!id,
});

const useGetOrganisationUsers = (id?: string | undefined) => useQuery({
  queryKey: ['Users'],
  queryFn: async (): Promise<IUser[]> => getOrganisationUsers(id),
  keepPreviousData: true,
  enabled: !!id,
});

export {
  useGetOrganisation,
  useGetOrganisationUsers,
};
