import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import globalEn from './en/global.json';
import globalFr from './fr/global.json';
import authEn from './en/auth.json';
import authFr from './fr/auth.json';
import usersEn from './en/users.json';
import usersFr from './fr/users.json';
import formationsEn from './en/formations.json';
import formationsFr from './fr/formations.json';
import plantsListsEn from './en/plantsLists.json';
import plantsListsFr from './fr/plantsLists.json';
import currentQuizEn from './en/currentQuiz.json';
import currentQuizFr from './fr/currentQuiz.json';
import articlesEn from './en/articles.json';
import articlesFr from './fr/articles.json';
import quizEn from './en/quiz.json';
import quizFr from './fr/quiz.json';

i18next.use(initReactI18next).init({
  lng: 'fr', // if you're using a language detector, do not define the lng option
  debug: process.env.NODE_ENV !== 'production',
  resources: {
    en: {
      global: globalEn,
      auth: authEn,
      users: usersEn,
      formations: formationsEn,
      plantsLists: plantsListsEn,
      currentQuiz: currentQuizEn,
      articles: articlesEn,
      quiz: quizEn,
    },
    fr: {
      global: globalFr,
      auth: authFr,
      users: usersFr,
      formations: formationsFr,
      plantsLists: plantsListsFr,
      currentQuiz: currentQuizFr,
      articles: articlesFr,
      quiz: quizFr,
    },
  },
});
