/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  IPlantsList,
  ArgsGetPlantsLists,
} from '../types/plantsList';

const getPlantsLists = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/plants-list/user`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.plantsLists;
};

const searchPlantsLists = async (params: ArgsGetPlantsLists) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/plants/list`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.options;
};

const getPlantsListById = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/plants-list/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.plantsList;
};

const createPlantsList = async (data: Partial<IPlantsList>)
: Promise<{ plantsList: IPlantsList }> => {
  const token = localStorage.getItem('token');

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/plants-list`,
    data,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return response.data;
};

const updatePlantsList = async (data: Partial<IPlantsList>)
: Promise<{ plantsList: IPlantsList }> => {
  const token = localStorage.getItem('token');
  const { _id, ...updatedData } = data;
  const response = await axios.put(
    `${process.env.REACT_APP_API_URL}/plants-list/${_id}`,
    updatedData,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return response.data;
};

export {
  searchPlantsLists,
  getPlantsLists,
  getPlantsListById,
  createPlantsList,
  updatePlantsList,
};
