import React, { useEffect } from 'react';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { resetError } from '../../../../actions/auth';

import {
  InputEmail,
  InputPassword,
  ErrorField,
} from '../../../../lib/HooksFormFields';

import styles from './signin-form.module.scss';

interface SignInProps {
  errorMessage: string,
  registerLink?: string,
  forgotPwdLink: string,
  submit: SubmitHandler<FieldValues>,
}

const SignInForm: React.FC<SignInProps> = ({
  errorMessage,
  forgotPwdLink,
  submit,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('auth');
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const watchEmail = watch('email');
  const watchPassword = watch('password');

  useEffect(() => resetError(dispatch), []);

  useEffect(() => {
    if (errorMessage) resetError(dispatch);
  }, [watchEmail, watchPassword]);

  function onSubmit(data: FieldValues) {
    submit(data);
  }

  function handlePressEnter(e: any) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['signin-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputEmail
            name="email"
            control={control}
            required
            className='login'
            label={t('label.email')}
          />
          {errors.email && (
            <ErrorField message={
              typeof errors.email.message === 'string' ? errors.email.message : null
            } />
          )}
        </div>
        <div className={styles['container-field']}>
          <InputPassword
            name="password"
            control={control}
            required
            className='login'
            requiredMessage={t('input.password.required')}
            label={t('label.password')}
          />
          {errors.password && (
            <ErrorField message={
              typeof errors.password.message === 'string' ? errors.password.message : null
            }/>
          )}
        </div>
        {(!errors.email && !errors.password) && errorMessage
          && (
          <p className={styles['message-error']}>
            {errorMessage}
          </p>
          )}
      </div>
      <div className={styles.containerSubmit}>
        {forgotPwdLink && (
          <NavLink to={forgotPwdLink}>
            {t('login.forgot')}
          </NavLink>
        )}
        <button className={styles.btn} onClick={handleSubmit(onSubmit)}>{t('login.submit')}</button>
      </div>
    </div>
  );
};

export default SignInForm;
