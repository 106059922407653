import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SET_ZOOMED_IMAGE } from '../../../actions/types';
import closeImg from './images/close.svg';

import styles from './zoomDesktop.module.scss';

export default function ZoomDesktop() {
  const { zoomedImage } = useSelector((s: any) => s.userQuizReducer);
  const dispatch = useDispatch();

  function handleClose() {
    dispatch({
      type: SET_ZOOMED_IMAGE,
      payload: null,
    });
  }

  return (
    <div onClick={() => handleClose()} className={`${styles.container} ${zoomedImage ? styles.open : ''}`}>
      <div className={styles.content}>
        <img src={zoomedImage} alt="zoomed"/>
        <button
          className={styles.close}
          onClick={() => handleClose()}
          >
          <img src={closeImg} alt='fermer' />
        </button>
      </div>
    </div>
  );
}
