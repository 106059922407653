import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { SET_USER_QUIZ_SETTINGS } from '../../../actions/types';
import { QuestionType } from '../../../types/userQuiz';
import { getTepicPicture } from '../../../utils';
import styles from './zoomMobile.module.scss';

export default function ZoomMobile({
  question,
}: {
  question: QuestionType,
}) {
  const { t } = useTranslation('quiz');
  const { settings } = useSelector((s: any) => s.userQuizReducer);
  const dispatch = useDispatch();

  const contentElements = useMemo(() => {
    const elements : any[] = [];
    if (!settings.isZoomMobile) {
      return elements;
    }
    if (question.pictures.length > 0) {
      question.pictures.forEach((p) => {
        elements.push({
          src: getTepicPicture(p),
          alt: p.file_name,
        });
      });
    }
    if (question.pairs.length > 0) {
      question.pairs.forEach((pair) => {
        if (pair?.pictures?.length > 0) {
          if (pair?.text) {
            elements.push({
              text: pair?.text,
            });
          }
          pair.pictures.forEach((p: any) => {
            elements.push({
              src: getTepicPicture(p),
              alt: p.file_name,
            });
          });
        }
      });
    }
    if (question.options.length > 0) {
      question.options.forEach((opt) => {
        if (opt?.pictures?.length > 0) {
          opt.pictures.forEach((p: any) => {
            elements.push({
              src: getTepicPicture(p),
              alt: p.file_name,
            });
          });
        }
      });
    }
    return elements;
  }, [question, settings.isZoomMobile]);

  function handleChangeSettings(obj: any) {
    dispatch({
      type: SET_USER_QUIZ_SETTINGS,
      payload: {
        ...settings,
        ...obj,
      },
    });
  }

  return (
    <div className={`${styles.container} ${settings.isZoomMobile ? styles.open : ''}`}>
      <h3>{t('current.zoom.title')}</h3>
      <div className={styles.content}>
        {contentElements.map((el: any, i :number) => (<div key={`picture-id-${i}`}>
          {el.src && <img src={el.src} alt={el.alt} />}
          {el.text && <p className={styles[settings.font]}>{el.text}</p>}
        </div>))}
      </div>
      <button
        className={styles.close}
        onClick={() => handleChangeSettings({ isZoomMobile: false })}
      >
        {t('current.zoom.close')}
      </button>
    </div>
  );
}
