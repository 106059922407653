import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RESET_CURRENT_QUIZ } from '../../actions/types';
import DashboardCount from '../../components/DashboardCount';
import HeaderMobile from '../../components/HeaderMobile';
import CustomLineChart, { DashboardItem } from '../../components/LineChart';
import useGetDashboard from '../../hooks/dashboard';
import styles from './dashboard.module.scss';

const LearnerDashboard = ({ dashboard }: { dashboard : any }) => {
  const { t } = useTranslation('global');
  const data : DashboardItem[] = useMemo(() => {
    const dates : DashboardItem[] = [];
    const currentDate = new Date();
    let value = 0;
    let prevValue = 0;
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    while (firstDayOfMonth.getMonth() === currentDate.getMonth()
    && firstDayOfMonth.getDate() <= currentDate.getDate()) {
      const dateFormated = format(firstDayOfMonth, 'dd/MM/yyyy');
      const valueFound = dashboard?.answers?.find(
        (a:any) => a.date === dateFormated,
      )?.percentage;

      if (valueFound) {
        value = parseFloat(valueFound.toFixed(2));
        prevValue = value;
      } else {
        value = prevValue;
      }
      dates.push({
        value,
        name: dateFormated,
        color: '#FF845E',
        unit: t('dashboard.progress.unit'),
      });
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }
    return dates;
  }, [dashboard]);
  return (
    <div className={styles.learner}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.counter}>
            <DashboardCount
              name="plant"
              count={dashboard?.plants}
            />
          </div>
          <div className={styles.counter}>
            <DashboardCount
              name="quiz"
              count={dashboard?.quiz}
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.stats}>
            <p className={styles.label}>{t('dashboard.myProgess')}</p>
            <CustomLineChart
              data={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const TutorDashboard = ({ dashboard }: { dashboard : any }) => {
  const { t } = useTranslation('global');
  const data : DashboardItem[] = useMemo(() => {
    const dates : DashboardItem[] = [];
    const currentDate = new Date();
    let value = 0;
    let prevValue = 0;
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    while (firstDayOfMonth.getMonth() === currentDate.getMonth()
    && firstDayOfMonth.getDate() <= currentDate.getDate()) {
      const dateFormated = format(firstDayOfMonth, 'dd/MM/yyyy');
      const valueFound = dashboard?.answers?.find(
        (a:any) => a.date === dateFormated,
      )?.percentage;

      if (valueFound) {
        value = parseFloat(valueFound.toFixed(2));
        prevValue = value;
      } else {
        value = prevValue;
      }

      dates.push({
        value,
        name: dateFormated,
        color: '#FF845E',
        unit: t('dashboard.progress.unit'),
      });
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }
    return dates;
  }, [dashboard]);
  return (
    <div className={styles.learner}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.counter}>
            <DashboardCount
              name="user"
              count={dashboard?.users}
            />
          </div>
          <div className={styles.counter}>
            <DashboardCount
              name="quiz"
              count={dashboard?.usersQuiz}
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.stats}>
            <p className={styles.label}>{t('dashboard.progress.average')}</p>
            <CustomLineChart
              data={data}
              color="#4C549E"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { t } = useTranslation('global');
  const { user: userAuth } = useSelector((d: any) => d.authReducer);
  const title = t('dashboard.title');
  const dispatch = useDispatch();
  const { data: dashboard } = useGetDashboard();
  useEffect(() => {
    dispatch({
      type: RESET_CURRENT_QUIZ,
    });
  }, []);

  return (
    <div className={styles.user}>
      <HeaderMobile title={title} />
      <div className={styles.page}>
        <header>
          <h1>{title}</h1>
         </header>
         {userAuth.role === 'Learner'
          && <LearnerDashboard
            dashboard={dashboard}
         />}
          {userAuth.role === 'Tutor'
          && <TutorDashboard
            dashboard={dashboard}
         />}
      </div>
    </div>
  );
};

export default Dashboard;
