import React, { useEffect } from 'react';
import {
  Routes, Route,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Page404 from './pages/Page404';
import Dashboard from './pages/dashboard';
import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Layout from './components/Layout';
import Organisations from './pages/organisations';
import Account from './pages/account';
import Toaster from './lib/Toaster';
import Users from './pages/users';
import User from './pages/user';
import UserDashboard from './pages/userDashboard';
import Subscription from './pages/subscription';
import Formations from './pages/formations';
import FormationForm from './pages/formationForm';
import FormationDetail from './pages/formationDetail';
import PlantsLists from './pages/plantsLists';
import EditPlantsList from './pages/plantsListEdit';
import Formation from './pages/Formation';
import CurrentQuiz from './pages/currentQuiz';
import ArticlesList from './pages/ArticlesList';
import Article from './pages/Article';
import LearnerQuizList from './pages/learnerQuizList';

const AppRoutes = () => {
  const { user } = useSelector((store: any) => store.authReducer);
  const languges = ['fr', 'en'];
  const { i18n } = useTranslation('global');

  function handleChangeLang(lang: string) {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  }
  useEffect(() => {
    if (window.navigator.language) {
      let lang = window.navigator.language.split('-')[0];
      if (!languges.find((l) => l === lang)) {
        lang = 'fr';
      }
      handleChangeLang(lang);
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {user?.role ? (
          <>
            <Route path="/quiz/:id" element={<CurrentQuiz />} />
            <Route path="/organisation" element={<Organisations />} />
            <Route path='/*' element={
              <Layout key='app'>
                <Routes>
                  <Route path='/' element={<Dashboard />} />
                  <Route path='/profile' element={<Account />} />
                  <Route path='/actus' element={<ArticlesList />} />
                  <Route path='/actus/:articleId' element={<Article />} />
                  <Route path='/formations/create' element={<FormationForm />} />
                  <Route path='/formations/detail/:id' element={<FormationDetail />} />
                  <Route path='/formations/:formationId' element={user.role === 'Learner' ? <Formation /> : <FormationForm />} />
                  <Route path='/formations' element={<Formations />} />
                  <Route path='/user-quiz' element={<LearnerQuizList />} />
                  <Route path='/plantsList/create' element={<EditPlantsList />} />
                  <Route path='/plantsList/:id' element={<EditPlantsList />} />
                  <Route path='/plantsList' element={<PlantsLists />} />
                  <Route path='/users' element={<Users />} />
                  <Route path='/users/create' element={<User />} />
                  <Route path='/users/:id' element={user.role === 'Tutor' ? <UserDashboard /> : <User />} />
                  <Route path='/subscription' element={<Subscription />} />
                  <Route path='*' element={<Page404 />} />
                </Routes>
              </Layout>
            }/>
          </>
        )
          : <Route path="*" element={<Page404 />} />
        }
      </Routes>
      <Toaster />
    </>
  );
};

export default AppRoutes;
