/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { ArgsGetArticles } from '../../types/article';

const getArticles = async (params: ArgsGetArticles) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/articles`,
    {
      headers: {
        Authorization: token,
      },
      params,
    },
  );
  return data?.articles;
};

const getArticleById = async (id?: string | null) => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/articles/${id}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.article;
};

const getArticlesList = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/articles/list`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.list;
};

export {
  getArticles,
  getArticleById,
  getArticlesList,
};
