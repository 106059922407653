import axios from 'axios';

const getUserQuiz = async () => {
  const token = localStorage.getItem('token');
  if (!token) return null;

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/quiz/user`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return data?.quiz;
};

export default getUserQuiz;
