import React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { IoChevronUp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import styles from './userListDesktop.module.scss';
import { IUser } from '../../../types/users';
import InputSearch from '../../InputSearch';
import FilterSearchParam from '../../FilterSearchParam';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { IOption } from '../../../types/options';

const Row = ({ user, role }: { user: IUser, role?: string }) => {
  const tags = useTagsToOptionsLang(user?.tags || []);
  const dateFormated = format(new Date(user.createdAt), 'dd.MM.yyyy');
  const tag = tags.length > 0 ? tags[0] : null;
  const group = user.groups.length > 0 ? user.groups[0] : null;
  return (<Link to={`/users/${user._id}`}
      className={styles.row}
    >
    <div className={styles.col}>
      <p>{user?.profile?.lastName}</p>
    </div>
    <div className={styles.col}>
      <p>{user?.profile?.firstName}</p>
    </div>
    <div className={styles.col}>
      {role
        ? <p className={styles.role}>{role}</p>
        : <div className={styles.tags}>
          {tag
            && <>
              <p key={tag.value}
                style={{ backgroundColor: tag.color }}
                >
                {tag.label}
              </p>
              {tags.length > 1
                && <p
                style={{ backgroundColor: tag.color }}
                >
                + {tags.length - 1}
              </p>
              }
            </>
          }
        </div>
      }
    </div>
    <div className={styles.col}>
      <div className={styles.tags}>
        {group
          && <>
            <p
              style={{ backgroundColor: '#abb0e4' }}
              >
              {group}
            </p>
            {user.groups.length > 1
              && <p
              style={{ backgroundColor: '#abb0e4' }}
              >
              + {user.groups.length - 1}
            </p>
            }
          </>
        }
      </div>
    </div>
    <div className={styles.col}>
      {dateFormated}
    </div>
  </Link>);
};

const UserListDesktop = ({
  users,
  tags,
  title,
  groups,
  roles,
}: {
  users: IUser[],
  tags: any,
  title: string,
  groups: IOption[]
  roles: IOption[]
}) => {
  const { t } = useTranslation('users');
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector((store: any) => store.authReducer);
  const { search = '', order = 'desc', sortKey = '' } = Object.fromEntries(searchParams);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 1) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (key: any) => {
    if (!key) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', key);
    params.set('order', order === 'asc' ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const labels = [
    {
      label: t('user.label.lastName'),
    },
    {
      label: t('user.label.firstName'),
    },
    {
      label: roles.length > 0 ? t('user.label.roles') : t('user.label.tags'),
    },
    {
      label: t('user.label.group'),
    },
    {
      label: t('user.label.createdAt'),
      sortKey: 'createdAt',
    },
  ];

  return (
    <div className={styles.desktop}>
    <div className={styles.header}>
      <h1>{title}</h1>
      <div className={styles.actions}>
        <div className={styles.search}>
          <InputSearch
            defaultValue={search}
            handleSearch={handleSearch}
            isToggle={true}
          />
        </div>
        {user.role === 'Owner'
          && <Link to={'/users/create'}
          className={`${styles.btn} ${styles.add}`}
        >
          <IoIosAdd />{t('user.new')}
        </Link>
        }
      </div>
    </div>
    <div className={styles.filters}>
      <div className={styles.filter}>
        <FilterSearchParam
          label={t('filters.tags')}
          name='tag'
          options={tags || []}
        />
      </div>
      <div className={styles.filter}>
        <FilterSearchParam
          label={t('filters.groups')}
          name='group'
          options={groups || []}
        />
      </div>
    </div>
    <div className={styles.content}>
      <div className={`${styles.row} ${styles.label}`}>
        {labels.map((l: any) => <div key={l.label}
          className={`${styles.col} ${l?.sortKey ? styles.sort : ''} ${order === 'desc' ? styles.desc : ''}`}
          onClick={() => handleSorting(l?.sortKey)}
        >
          {l?.sortKey === sortKey && <div className={styles.icon}>
            <IoChevronUp />
          </div>
          }
          <p>{l.label}</p>
        </div>)}
      </div>
      <div className={styles.list}
      >
        {users.map((u: IUser) => (<Row
          key={u._id}
          user={u}
          role={roles.find((r: IOption) => r.value === u.role)?.label}
        />))}
      </div>
    </div>
  </div>
  );
};
export default UserListDesktop;
