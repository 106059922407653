import React, {
  forwardRef, useEffect, useImperativeHandle,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import {
  InputText,
  ErrorField,
  Textarea,
} from '../../../lib/HooksFormFields';
// import Tag from '../../Tag';
import styles from './formation-form.module.scss';
import SelectMultiple from '../../SelectMultiple';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { IFormation } from '../../../types/formations';

import { useGetOrganisation } from '../../../hooks/organisations';
import InputFile from '../../InputFile';
import { useUpdateFormation } from '../../../hooks/formations';

const FormationForm = forwardRef(({
  formation = null,
} : {
  formation?: IFormation | null
}, ref) => {
  const { t } = useTranslation(['global', 'formations']);
  const organisationId = localStorage.getItem('organisation');
  const { data: organisation } = useGetOrganisation(organisationId || '');

  const {
    mutateAsync: updateFormation,
  } = useUpdateFormation();

  const {
    control,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = useForm(formation ? {
    defaultValues: formation,
  } : {});

  const tags = organisation?.tags || [];

  const tagsTranslated = useTagsToOptionsLang(tags);

  const errorsForm : any = errors;

  useEffect(() => {
    if (formation) {
      reset({
        ...formation,
        tags: formation.tags.map((tag: any) => tag._id),
      });
    }
  }, [formation]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const isValid = await trigger();
      let data = null;
      if (isValid) {
        data = getValues();
        data = {
          _id: data?._id,
          name: data.name,
          description: data.description,
          tags: data.tags,
        };
      }
      return data;
    },
  }), []);

  return (
    <div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <InputText
            name="name"
            control={control}
            label={t('form.fields.name', { ns: 'formations' })}
            rules={{
              required: t('requiredField', { ns: 'global' }),
            }}
          />
          {errorsForm?.name && <ErrorField message={errorsForm.name.message} />}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerField}>
          <Textarea
            name="description"
            control={control}
            label={t('form.fields.description', { ns: 'formations' })}
          />
        </div>
      </div>

      <div className={styles.row}>
        <div className={`${styles.containerField} ${styles.cover}`}>
          <h4>{t('form.picture.label', { ns: 'formations' })}</h4>
          <InputFile
            fileId={formation?.picture?._id}
            submit={(picture: string) => updateFormation({ _id: formation?._id, picture }) }
            id={'formation.picture'}
          >
            {formation?.picture?.path
              ? <>
                <div className={`${styles.picture} ${formation?.picture?.path && styles.media}`}>
                  <img src={`${process.env.REACT_APP_API_URL}/files/public/${formation?.picture.path}`}
                    className={styles.img}
                  />
              </div>
            </> : <div className={`${styles.picture}`}>
              <FiDownload />
            </div>}
          </InputFile>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.containerField}>
          <SelectMultiple
            label={t('form.fields.tags', { ns: 'formations' })}
            name="tags"
            control={control}
            options={tagsTranslated}
          />
        </div>
      </div>
    </div>
  );
});

export default FormationForm;
