import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import HeaderMobile from '../../components/HeaderMobile';
import styles from './formations.module.scss';
import { useGetOrganisation } from '../../hooks/organisations';

import useTagsToOptionsLang from '../../hooks/useTagsToOptionsLang';

import { useGetFormations } from '../../hooks/formations';
import FormationsListMobile from '../../components/formations/formationsListMobile';
import { IFormation } from '../../types/formations';
import FormationsListDesktop from '../../components/formations/formationsListDesktop';

const Formations = () => {
  const { t } = useTranslation('formations');
  const [searchParams] = useSearchParams();
  const {
    search = '', tag = '', sortKey = '', isTepik = '', order = 'desc',
  } = Object.fromEntries(searchParams);

  const organisationId = localStorage.getItem('organisation');
  const { data: organisation } = useGetOrganisation(organisationId || '');

  const {
    data: formations = [],
  } = useGetFormations();

  const tags = useTagsToOptionsLang(organisation?.tags || []);

  const filteredFormations = useMemo(() => {
    let formationsList = formations || [];
    if (search) {
      formationsList = formationsList.filter((f:IFormation) => {
        let isIn = false;
        if (f.name.toLowerCase().includes(search.toLowerCase())) {
          isIn = true;
        }
        return isIn ? f : null;
      });
    }
    if (isTepik) {
      formationsList = formationsList.filter((f:IFormation) => {
        let isIn = false;
        if (f.isTepik === true && isTepik === 'true') {
          isIn = true;
        }
        if (f.isTepik === false && isTepik === 'false') {
          isIn = true;
        }
        return isIn ? f : null;
      });
    }
    if (tag) {
      formationsList = formationsList.filter((u:IFormation) => {
        let isIn = false;
        if (u.tags?.find((ta: any) => ta._id === tag)) {
          isIn = true;
        }
        return isIn ? u : null;
      });
    }
    if (sortKey) {
      if (sortKey === 'name') {
        formationsList = formationsList.sort(
          (a: IFormation, b: IFormation) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          },
        );
      }
      if (order === 'asc') {
        formationsList = formationsList.reverse();
      }
    }
    return formationsList;
  }, [formations, search, tag, order, isTepik, sortKey]);

  const title = `${filteredFormations?.length || 0} ${t('title')} `;

  return (
    <div className={styles.formations}>
      <HeaderMobile title={title} />
      <div className={styles.page}>
        <FormationsListMobile
          formations={filteredFormations}
          tags={tags}
        />
         <FormationsListDesktop
          title={title}
          formations={filteredFormations}
          tags={tags}
        />
      </div>
    </div>
  );
};

export default Formations;
