import React from 'react';
import Nav from '../Nav';
import styles from './layout.module.scss';

const Layout = ({ children } : { children: React.ReactNode }) => (
  <div className={styles.global}>
    <Nav />
    <div className={styles.container}>
      {children}
    </div>
  </div>
);

export default Layout;
