import React, { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../../actions/auth';
import imgLogin from '../../assets/images/login.svg';
import logo from '../../assets/images/logo.svg';
import imgPlant from '../../assets/images/plant.svg';
import EmailResetPwdForm from '../../components/form/AuthForm/EmailResetPwdForm';

import styles from './forgot-password.module.scss';

const ForgotPassword = () => {
  const authReducer = useSelector((d: any) => d.authReducer);
  const { t } = useTranslation('auth');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (authReducer.user?._id) navigate('/');
  }, [authReducer.user]);

  function submitForgot(data: FieldValues) {
    forgotPassword(dispatch, data).then((res: any) => {
      if (res?.status === 200) {
        setMessage(t('forgot.success'));
      }
    });
  }

  return (
    <div className={styles['forgot-password']}>
      <div className={styles.picture}>
        <div className={styles.mobile}>
          <img src={imgPlant} alt="login" />
          <p>{t('login.welcome')}</p>
          <h2>{t('login.start1')}<br/>{t('login.start2')}</h2>
        </div>
        <div className={styles.desktop}>
          <div className={styles.logo}>
            <img src={logo} alt="login" />
          </div>
          <img src={imgLogin} alt="login" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.form}>
          <h3>{t('forgot.new')}</h3>
          <EmailResetPwdForm
            submit={submitForgot}
            signinLink="/"
            succeedMessage={message}
            errorMessage={authReducer.error}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
