import React, { useEffect } from 'react';
import { FieldValues, get, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile } from '../../actions/auth';
import HeaderMobile from '../../components/HeaderMobile';
import InputFile from '../../components/InputFile';
import pen from '../../assets/images/pen.svg';
import { ErrorField, InputEmail, InputText } from '../../lib/HooksFormFields';
import styles from './account.module.scss';

const Account = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((d: any) => d.authReducer);
  const { t } = useTranslation('users');
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user]);

  async function onSubmit(values: FieldValues) {
    console.log(control, errors);
    await updateProfile(dispatch, values);
  }

  return (
    <div className={styles.user}>
      <HeaderMobile title={t('title.profile')} />
      <div className={styles.page}>
        <header>
          <h1>{t('title.profile')}</h1>
          <div className={styles.actions}>
            <button
              type="button"
              className={styles.submit}
              onClick={handleSubmit(onSubmit)}
            >
              {t('btn.save', { ns: 'global' })}
            </button>
          </div>
        </header>
        <div className={styles.content}>
          <div className={styles.form}>
            {user?._id
              && <section>
                <label>{t('user.label.picture')}</label>
                <InputFile
                  submit={(picture: string) => updateProfile(dispatch, { picture }) }
                  id={'profile.picture'}
                >
                  {user?.picture?.path
                    ? <>
                      <div className={styles.picture}>
                        <div className={styles.icon}>
                          <img src={pen} alt="edit"/>
                        </div>
                        <img src={`${process.env.REACT_APP_API_URL}/files/public/${user.picture.path}`}
                          className={styles.img}
                        />
                    </div>
                  </> : <div className={`${styles.picture} ${user?.role === 'Admin' ? styles.admin : ''}`}>
                    <div className={styles.icon}>
                      <img src={pen} alt="edit"/>
                    </div>
                    <p>{user.profile.firstName[0]}{user.profile.lastName[0]}</p>
                  </div>}
                </InputFile>
              </section>
            }
            <section>
              <h2>{t('user.infos')}</h2>
              <div className={styles.row}>
                <div className={styles.field}>
                  <InputText
                    label={t('user.label.firstName')}
                    control={control}
                    name="profile.firstName"
                    className='primary'
                    rules={{ required: t('requiredField', { ns: 'global' }) }}
                  />
                  {get(errors, 'profile')?.firstName?.message
                    && <ErrorField message={get(errors, 'profile')?.firstName?.message} />}
                </div>
                <div className={styles.field}>
                  <InputText
                    label={t('user.label.lastName')}
                    control={control}
                    name="profile.lastName"
                    className='primary'
                    rules={{ required: t('requiredField', { ns: 'global' }) }}
                  />
                  {get(errors, 'profile')?.lastName?.message
                    && <ErrorField message={get(errors, 'profile')?.lastName?.message} />}
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.field}>
                  <InputText
                    label={t('user.label.phone')}
                    control={control}
                    name="phone"
                    className='primary'
                  />
                </div>
                <div className={styles.field}>
                  <InputEmail
                    label={t('user.label.email')}
                    control={control}
                    name="email"
                    className='primary'
                    required
                  />
                  {get(errors, 'email')?.message
                    && <ErrorField message={get(errors, 'email')?.message} />}
                </div>
              </div>
            </section>

          </div>
        </div>
        <div className={styles.mobile}>
          <button
            type="button"
            className={styles.submit}
            onClick={handleSubmit(onSubmit)}
          >
            {t('btn.save', { ns: 'global' })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Account;
