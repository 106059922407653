import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import backIcon from '../../assets/images/back-grey.svg';
import {
  useGetUser,
} from '../../hooks/users';
import HeaderMobile from '../../components/HeaderMobile';
import styles from './userDashboard.module.scss';

const UserDashboard = () => {
  const { id } = useParams();
  const { data: user } = useGetUser(id);
  const [selectedPanel, setSelecetedPanel] = useState('infos');
  const { t } = useTranslation('users');
  const back = {
    label: t('user.back'),
    url: '/users',
  };
  return (
    <div className={styles.user}>
      <HeaderMobile back={back} />
      <div className={styles.page}>
        <header>
          <Link to={back.url}>
            <img src={backIcon} alt={back.label} />
            <p>{back.label}</p>
          </Link>
        </header>
        <div className={styles.toggle}>
          <button
            className={selectedPanel === 'infos' ? styles.active : ''}
            onClick={() => setSelecetedPanel('infos')}
          >
            {t('infos')}
          </button>
          <button
            className={selectedPanel === 'stats' ? styles.active : ''}
            onClick={() => setSelecetedPanel('stats')}
          >
            {t('stats')}
          </button>
        </div>
         {user
          && <div className={styles.content}>
            <div className={`${styles.col} ${selectedPanel === 'infos' ? styles.active : ''}`}>
              <div className={styles.name}>
                <h1>{user.profile.firstName} {user.profile.lastName}</h1>
                <p>{user?.email}</p>
              </div>
              <div className={styles.picture}>
                {user?.picture?.path
                  ? <>
                      <img src={`${process.env.REACT_APP_API_URL}/files/public/${user.picture.path}`}
                        className={styles.img}
                      />
                    </>
                  : <p>{user.profile.firstName[0]}{user.profile.lastName[0]}</p>
                }
              </div>
            </div>
            <div className={`${styles.col} ${selectedPanel === 'stats' ? styles.active : ''}`}>

            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default UserDashboard;
