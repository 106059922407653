import React, { useRef, useState } from 'react';
import { IoIosAdd } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import filterIcon from '../../../assets/images/filter.svg';
import styles from './formationsListMobile.module.scss';

import InputSearch from '../../InputSearch';
import FilterSearchParam from '../../FilterSearchParam';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { IOption } from '../../../types/options';
import { IFormation } from '../../../types/formations';
import { useCreateFormation } from '../../../hooks/formations';
import FormationCard from '../FormationCard';

const Card = ({ formation }: { formation: IFormation }) => {
  const { t } = useTranslation('formations');
  const { user } = useSelector((d: any) => d.authReducer);
  const tags = useTagsToOptionsLang(formation?.tags || []);
  const tag = tags.length > 0 ? tags[0] : null;
  return (<Link to={user.role !== 'Learner' ? `/formations/detail/${formation._id}` : `/formations/${formation._id}`}
      className={styles.card}
    >
    <div className={styles.row}>
      <h4>{formation.name}</h4>
      <p>{formation.quiz?.length}</p>
    </div>
    <div className={styles.row}>
      <div className={styles.tags}>
        {tag
          && <>
            <p key={tag.value}
              style={{ backgroundColor: tag.color }}
              >
              {tag.label}
            </p>
            {tags.length > 1
              && <p
              style={{ backgroundColor: tag.color }}
              >
              + {tags.length - 1}
            </p>
            }
          </>
        }
      </div>
      <div className={styles.state}>
        <span style={{
          backgroundColor: formation.isClose ? '#00875E' : '#00875E',
        }}/>
        <p>{formation.isClose ? t('formation.close') : t('formation.open')}</p>
      </div>
    </div>
  </Link>);
};

const FormationsListMobile = ({
  formations,
  tags,
}: {
  formations: IFormation[],
  tags: IOption[],
}) => {
  const { t } = useTranslation('formations');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useSelector((d: any) => d.authReducer);
  const { search = '', tag = '' } = Object.fromEntries(searchParams);
  const filtersRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(!!search || !!tag);
  const {
    mutateAsync: createFormation,
  } = useCreateFormation();

  const types = [
    {
      label: t('tepik'),
      value: 'true',
    },
    {
      label: t('tutor'),
      value: 'false',
    },
  ];

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 1) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleCreateFormation = async () => {
    const data = { name: t('formation.new') };
    const { formation: createdFormation }: any = await createFormation(data);
    if (createdFormation) navigate(`/formation/edit/${createdFormation?._id}?isCreate=true`);
  };

  return (
    <div className={styles.mobile}>
    <div
      className={styles.filters}
      ref={filtersRef}
    >
      <div className={styles.btns}>
        {user.role !== 'Learner'
          ? <div
            onClick={() => handleCreateFormation()}
            className={`${styles.btn} ${styles.add}`}
          >
            <IoIosAdd />{t('formation.new')}
          </div>
          : <span></span>
        }
        <button
          className={styles.toggle}
          onClick={() => setIsOpen(!isOpen)}
        >
          <img src={filterIcon} alt="filter" />
        </button>
      </div>
      {isOpen
        && <div className={styles.panel}>
          <div className={styles.search}>
            <InputSearch
              defaultValue={search}
              handleSearch={handleSearch}
            />
          </div>
          <div className={styles['filters-row']}>
            <div className={styles.filter}>
              <FilterSearchParam
                label={t('filters.tags')}
                name='tag'
                options={tags || []}
              />
            </div>
            {user?.role === 'Learner'
              && <div className={styles.filter}>
                <FilterSearchParam
                  label={t('filters.type')}
                  name='isTepik'
                  options={types || []}
                />
              </div>
            }
          </div>
        </div>
      }
    </div>
    <div className={styles.list}
      style={isOpen ? {
        height: `calc(100% - ${filtersRef.current?.getBoundingClientRect().height}px)`,
      } : {
        height: 'calc(100% - 83px)',
      }}
    >
      {user.role !== 'Learner'
        ? <>
        {formations.map((f :IFormation) => (<Card
            key={f._id}
            formation={f}
        />))}
      </> : <div className={styles.grid}>
          {formations.map((f: IFormation) => (<FormationCard
            key={f._id}
            formation={f}
          />))}
        </div>
      }

    </div>
  </div>
  );
};

export default FormationsListMobile;
