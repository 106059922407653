import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { GoArrowLeft } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import defaultPicture from '../../../assets/images/plante.jpg';
import { GET_CURRENT_QUIZ } from '../../../actions/types';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { useAppDispatch } from '../../../reducers';
import { getApiPicture } from '../../../utils';
import quizImg from './images/quiz.svg';
import styles from './QuizModal.module.scss';

export default function QuizModal() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('currentQuiz');
  const formationId = useParams()?.formationId;
  const ref = useRef<HTMLDivElement>(null);
  const { current } = useSelector((s: any) => s.userQuizReducer);
  const { quiz } = current;
  const picture = quiz?.picture?.path ? getApiPicture(quiz?.picture?.path) : defaultPicture;
  function closeModale() {
    dispatch({
      type: GET_CURRENT_QUIZ,
      payload: null,
    });
  }

  useOutsideClick(ref, closeModale);

  return (
    <div className={`${styles.container} ${quiz ? styles.active : ''}`}>
      <button
        type='button'
        className={styles.back}
        onClick={() => closeModale()}
      >
        <GoArrowLeft />
      </button>
      <div className={styles.illu}>
        <img src={quizImg} alt="quizz" />
      </div>
      {quiz
      && <div ref={ref} className={styles.content}>
        <div className={`${styles.picture} ${styles.desktop}`}>
          {picture && <img src={picture} />}
        </div>
        <div className={styles.col}>
          <div className={`${styles.picture} ${styles.mobile}`}>
            {picture && <img src={picture} />}
          </div>
          <div className={styles.infos}>
            <h2>{quiz.name}</h2>
            <label>{t('label.description')}</label>
            <p>{quiz.description}</p>
          </div>
          {quiz?.plants?.length
            && <div className={styles.plants}>
              <p>{quiz?.plants?.length} {t('label.plants')}</p>
            </div>
          }
          <div className={styles.count}>
            <p>{quiz.questions.length}</p>
            <BsQuestionCircleFill />
          </div>
          <div className={styles.start}>
            <Link
              to={`/quiz/${quiz._id}${formationId ? `?formation=${formationId}` : ''}`}
            >
            {t('start')}
            </Link>
          </div>
        </div>
      </div>
      }
    </div>
  );
}
