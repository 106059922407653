import {
  GET_CURRENT_QUIZ,
  // GET_USER_QUIZZES,
  POST_ANSWER,
  RESET_CURRENT_QUIZ,
  SET_USER_QUIZ_SETTINGS,
  SET_ZOOMED_IMAGE,
  // SET_USER_QUIZZ_FILTERS,
  START_QUIZ,
  USER_QUIZZ_ERROR,
  USER_QUIZZ_LOADING,
} from '../actions/types';
import { ActionReducer } from '../types';
import { Answers, Quizz } from '../types/userQuiz';

export type UserQuizzFilters = {
  search: string,
};

export type UserQuizSettings = {
  font: string,
  isSound: boolean,
  isZoom: boolean,
  isZoomMobile: boolean,
};

export type CurrentQuizz = {
  quiz: Quizz | null,
  answers: Answers | null | any,
};

interface UserQuizzState {
  totalQuizz: number | null,
  isLoading: string[],
  error: string | null,
  filters: UserQuizzFilters,
  current: CurrentQuizz,
  settings: UserQuizSettings,
  zoomedImage: string | null,
}

const DEFAULT_STATE: UserQuizzState = {
  totalQuizz: null,
  current: {
    quiz: null,
    answers: null,
  },
  isLoading: [],
  error: null,
  filters: {
    search: '',
  },
  zoomedImage: null,
  settings: {
    font: 'xs',
    isSound: false,
    isZoom: false,
    isZoomMobile: false,
  },
};

// eslint-disable-next-line default-param-last
const userQuizReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = { ...state };
  const entries = updatedState?.current?.answers?.entries
    ? [...updatedState.current.answers.entries] : [];
  const answerIndex : number | undefined = updatedState?.current?.answers?.entries?.findIndex(
    (e: any) => e?.question === action?.payload?.question,
  );
  switch (action.type) {
    case SET_USER_QUIZ_SETTINGS:
      updatedState = {
        ...updatedState,
        settings: action.payload,
        zoomedImage: null,
      };
      break;
    case START_QUIZ:
      updatedState = {
        ...updatedState,
        current: {
          ...updatedState.current,
          answers: action.payload,
        },
      };
      break;
    case POST_ANSWER:
      if (answerIndex && answerIndex >= 0) {
        entries[answerIndex] = { ...action.payload };
      } else {
        entries.push(action.payload);
      }
      updatedState = {
        ...updatedState,
        current: {
          ...updatedState.current,
          answers: {
            ...updatedState.current.answers,
            entries: [...entries],
          },
        },
      };
      break;
    case RESET_CURRENT_QUIZ:
      updatedState = {
        ...updatedState,
        current: {
          quiz: null,
          answers: null,
        },
      };
      break;
    case USER_QUIZZ_LOADING:
      updatedState = {
        ...updatedState,
        isLoading: [...updatedState.isLoading, action.payload],
      };
      break;
    case SET_ZOOMED_IMAGE:
      updatedState = {
        ...updatedState,
        zoomedImage: action.payload,
      };
      break;
    case GET_CURRENT_QUIZ:
      updatedState = {
        ...updatedState,
        isLoading: updatedState.isLoading.filter((d) => d !== action.type),
        current: {
          ...updatedState.current,
          quiz: action.payload,
        },
      };
      break;
    case USER_QUIZZ_ERROR:
      updatedState = { ...updatedState, error: action.payload };
      break;
    default:
      updatedState = { ...updatedState };
      break;
  }
  return updatedState;
};

export default userQuizReducer;
