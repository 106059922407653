import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import backIcon from '../../assets/images/back-grey.svg';
import HeaderMobile from '../../components/HeaderMobile';
import styles from './formation.module.scss';
import { useGetFormationById } from '../../hooks/formations';
import QuizList from '../../components/QuizzComponents/QuizList';
import { FormationQuiz } from '../../types/formations';
import QuizModal from '../../components/QuizzComponents/QuizModal';

const Formation = () => {
  const { t } = useTranslation('formations');
  const { formationId } = useParams();
  const { data: formation } = useGetFormationById(formationId);

  const back = {
    label: t('formation.back'),
    url: '/formations',
  };
  return (
    <div className={styles.user}>
      <HeaderMobile back={back} />
      <div className={styles.page}>
        <header>
          <Link to={back.url}>
            <img src={backIcon} alt={back.label} />
            <p>{back.label}</p>
          </Link>
        </header>
        <div className={styles.content}>
          <h1>{formation?.name}</h1>
          <p className={styles.description}>{formation?.description}</p>
          <h2>{t('formation.todo')}</h2>
          <div className={styles.list}>
            {formation?.quiz && <QuizList
              isFormation
              list={formation.quiz.filter((q) => !q.isValid).map(
                (q: FormationQuiz) => ({ ...q.quiz, isNext: q.isNext }),
              )}
              />}
          </div>
          <h2>{t('formation.done')}</h2>
          <div className={styles.list}>
            {formation?.quiz && <QuizList
              list={formation.quiz.filter(
                (q) => q.isValid,
              ).map((q: FormationQuiz) => ({ ...q.quiz, score: q.correct }))}
              />}
          </div>
        </div>
        <QuizModal />
      </div>
    </div>
  );
};

export default Formation;
