import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import imgLogin from '../../assets/images/login.svg';
import logo from '../../assets/images/logo.svg';
import imgPlant from '../../assets/images/plant.svg';
import { IOrganisation } from '../../types/organisations';

import styles from './organisations.module.scss';

const Organisations = () => {
  const { user } = useSelector((d: any) => d.authReducer);
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  async function chooseOrganisation(id: string) {
    await localStorage.setItem('organisation', id);
    navigate('/');
  }

  return (
    <div className={styles.organistions}>
      <div className={styles.picture}>
        <div className={styles.mobile}>
          <img src={imgPlant} alt="login" />
          <p>{t('login.welcome')}</p>
          <h2>{t('organisation.start1')}<br/>{t('login.start2')}</h2>
        </div>
        <div className={styles.desktop}>
          <div className={styles.logo}>
            <img src={logo} alt="login" />
          </div>
          <img src={imgLogin} alt="login" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.form}>
          <h3>{t('organisation.choose')}</h3>
          <div className={styles.list}>
            {user.organisations.map((o: IOrganisation) => (
              <button
                key={o._id}
                onClick={() => chooseOrganisation(o._id)}
              >
                {o.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organisations;
