import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { QuestionType } from '../../../types/userQuiz';
import { getTepicPicture } from '../../../utils';
import styles from './QuestionOptions.module.scss';

const AnswerOption = ({
  option,
  optionsCount = 0,
  answerType,
  selectedOptions,
  correction,
  handleChange,
}: {
  option: any,
  optionsCount: number,
  answerType: string,
  correction: string[],
  selectedOptions: string[],
  handleChange: (value: any) => void
}) => {
  const { settings } = useSelector((s: any) => s.userQuizReducer);
  const isActive = selectedOptions?.find((id) => id === option._id);

  const getCorrectionClassName = useMemo(() => {
    let className = '';
    if (correction) {
      if (correction.find((c) => c === option._id)) {
        className = styles.correct;
      } else if (isActive) {
        className = styles.wrong;
      }
    }
    return className;
  }, [correction, option]);

  function handleChangeOption() {
    if (answerType === 'uniqOption') {
      return handleChange([option._id]);
    }
    if (answerType === 'multiOption') {
      let selected = [...selectedOptions];
      const found = selected.find((s) => s === option._id);
      selected = found ? selected.filter((s) => s !== option._id) : [...selected, option._id];
      return handleChange(selected);
    }
    return null;
  }

  if (option?.pictures?.length > 0) {
    return (<button
      type='button'
      className={`${styles['picture-option']} ${option.text ? styles.withText : ''} ${getCorrectionClassName} ${styles[`row-${optionsCount}`]} ${!isActive && selectedOptions.length > 0 ? styles['not-active'] : ''} ${isActive ? styles.active : ''}`}
      onClick={() => handleChangeOption()}
    >
      {option.text && <p className={`${styles[settings.font]}`}>{option.text}</p>}
      <div
        className={`${styles['option-pictures']} ${styles[`row-${option.pictures.length}`]}`}
      >
        {option.pictures.map((p: any) => <img
          key={p.file_name}
          src={getTepicPicture(p)}
          alt={p.file_name}/>)}
      </div>
    </button>
    );
  }
  if (option?.pictures?.length === 0 && option?.text) {
    return (<button
      type='button'
      className={`${styles['text-option']} ${getCorrectionClassName} ${isActive ? styles.active : ''}`}
      onClick={() => handleChangeOption()}
    >
      <p className={`${styles[settings.font]}`}>{option.text}</p>
    </button>
    );
  }
  return null;
};

const Options = ({
  options,
  selectedOptions,
  answerType,
  correction,
  handleChange,
  disabled = false,
}:{
  options: any,
  answerType: string,
  selectedOptions: string[],
  disabled: boolean,
  correction: string[],
  handleChange: (value: any) => void
}) => {
  const withPictures = options.find((opt: QuestionType) => opt.pictures.length > 0);
  return (
    <div className={`${styles.options} ${disabled ? styles.disabled : ''} ${withPictures ? styles.withPictures : ''}`}>
      {options.map((opt : any) => <AnswerOption
        key={opt._id}
        handleChange={handleChange}
        answerType={answerType}
        selectedOptions={selectedOptions}
        correction={correction}
        optionsCount={options?.length}
        option={opt}
      />)}
    </div>
  );
};

export default Options;
