import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import backIcon from '../../assets/images/back-grey.svg';
import HeaderMobile from '../../components/HeaderMobile';
import { useGetArticleById } from '../../hooks/articles';
import useTagsToOptionsLang, { ITagTranslated } from '../../hooks/useTagsToOptionsLang';
import styles from './article.module.scss';
import { ArticleField } from '../../types/article';

const Field = ({ field }: { field: ArticleField }) => {
  if (field.type === 'H1' && field?.value) {
    return <h2>{field.value}</h2>;
  }
  if (field.type === 'text' && field?.value) {
    return <div className={styles.text} dangerouslySetInnerHTML={{ __html: field.value }} />;
  }
  if (field.type === 'image' && field?.media?.path) {
    return <div className={styles.media}>
      <img src={`${process.env.REACT_APP_API_URL}/files/public/${field?.media?.path}`} alt="picture" />
    </div>;
  }
  if (field.type === 'video' && field?.media?.path) {
    return <div className={styles.media}>
          <video controls>
          <source
            src={`${process.env.REACT_APP_API_URL}/files/public/${field?.media.path}`}
            type={field.media.mimetype}
          />
      </video>
     </div>;
  }
  return null;
};

const Article = () => {
  const { t, i18n } = useTranslation('articles');
  const lang = i18n.language;

  const { articleId } = useParams();

  const { data } = useGetArticleById(articleId);
  const article = data;

  const back = {
    label: t('back'),
    url: '/actus',
  };
  const date = article?.publicationDate && new Date(article?.publicationDate);
  const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat(lang, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const formattedDate: string | null = date ? formatter.format(date) : null;
  const tags = useTagsToOptionsLang(article?.tags || []);

  return (
    <div className={styles.article}>
      <HeaderMobile back={back} />
      <div className={styles.page}>
        <header>
          <Link to={back.url}>
            <img src={backIcon} alt={back.label} />
            <p>{back.label}</p>
          </Link>
        </header>
        <div className={styles.content}>
          <div className={styles.title}>
            <p>{t('article.date', { date: formattedDate })}</p>
            <h1>{article?.title}</h1>
          </div>
          <div className={styles.mobile}>
            <div className={styles.tags}>
              {tags.map((tag: ITagTranslated) => <p key={`${tag.value}`}>{tag.label}</p>)}
            </div>
            <div className={styles.creator}>
              <div className={styles.picture}>
                {article?.creator?.picture?.path
                  ? <img src={`${process.env.REACT_APP_API_URL}/files/public/${article?.creator.picture.path}`} alt="picture" />
                  : <span>
                {article?.creator?.profile?.firstName[0]}
                {article?.creator?.profile?.lastName[0]}
                </span>
                }
              </div>
              <p>{article?.creator?.profile?.firstName} {article?.creator?.profile?.lastName}</p>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              {article?.cover?.path
                && <div className={styles.cover}>
                  <img src={`${process.env.REACT_APP_API_URL}/files/public/${article?.cover?.path}`} alt="picture" />
                </div>
              }
              {article?.description
                && <div className={styles.description} dangerouslySetInnerHTML={{
                  __html: article?.description || '',
                }} />
              }
              {article?.fields.map((f: ArticleField) => <Field key={f._id} field={f} />)}
            </div>
            <div className={styles.col}>
              <label>{t('filters.tags')}</label>
              <div className={styles.tags}>
                {tags.map((tag: ITagTranslated) => <p key={`${tag.value}`}>{tag.label}</p>)}
              </div>
              <label>{t('article.author')}</label>
              <div className={styles.creator}>
                <div className={styles.picture}>
                  {article?.creator?.picture?.path
                    ? <img src={`${process.env.REACT_APP_API_URL}/files/public/${article?.creator.picture.path}`} alt="picture" />
                    : <span>
                  {article?.creator?.profile?.firstName[0]}
                  {article?.creator?.profile?.lastName[0]}
                  </span>
                  }
                </div>
                <p>{article?.creator?.profile?.firstName} {article?.creator?.profile?.lastName}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
