import React from 'react';

import { useTranslation } from 'react-i18next';
import { IoAdd } from 'react-icons/io5';
import styles from './formationQuizListMobile.module.scss';

import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { FormationQuiz } from '../../../types/formations';

const Card = ({ quiz }: { quiz: FormationQuiz }) => {
  const tags = useTagsToOptionsLang(quiz?.quiz?.tags || []);
  const tag = tags.length > 0 ? tags[0] : null;
  return (<div
      className={styles.card}
    >
    <div className={styles.row}>
      <h4>{quiz?.quiz?.name}</h4>
      <p>{quiz.successCondition} / {quiz.quiz.questions.length}</p>
    </div>
    <div className={styles.row}>
      <div className={styles.tags}>
        {tag
          && <>
            <p key={tag.value}
              style={{ backgroundColor: tag.color }}
              >
              {tag.label}
            </p>
            {tags.length > 1
              && <p
              style={{ backgroundColor: tag.color }}
              >
              + {tags.length - 1}
            </p>
            }
          </>
        }
      </div>
    </div>
  </div>);
};

const FormationQuizListMobile = ({
  quiz,
  openModal,
}: {
  quiz: any[],
  openModal: () => void,
}) => {
  const { t } = useTranslation('formations');
  return (
    <div className={styles.mobile}>
      <div className={styles.add}>
        <button
          className={`${styles.btn} ${styles.add}`}
          onClick={() => openModal()}
        >
          <IoAdd /> {t('formation.quiz.add')}
        </button>
      </div>
      <div className={styles.list}
      >
        {quiz.map((q : FormationQuiz) => (<Card
          key={q._id}
          quiz={q}
        />))}
      </div>
  </div>
  );
};

export default FormationQuizListMobile;
