import React from 'react';

import { useTranslation } from 'react-i18next';
import HeaderMobile from '../../components/HeaderMobile';
import styles from './subscription.module.scss';

const Subscription = () => {
  const { t } = useTranslation('global');
  const title = t('subscription.title');
  return (
    <div className={styles.user}>
      <HeaderMobile title={title} />
      <div className={styles.page}>
        <header>
          <h1>{title}</h1>
         </header>
      </div>
    </div>
  );
};

export default Subscription;
