import React from 'react';

import { useTranslation } from 'react-i18next';
import { IoAdd } from 'react-icons/io5';
import styles from './formationLearnersMobile.module.scss';

import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { IUser } from '../../../types/users';

const Card = ({ user }: { user: IUser }) => {
  const tags = useTagsToOptionsLang(user?.tags || []);
  const tag = tags.length > 0 ? tags[0] : null;
  return (<div
      className={styles.card}
    >
    <div className={styles.row}>
      <h4>{user.profile?.firstName} {user.profile?.lastName}</h4>
    </div>
    <div className={styles.row}>
      <div className={styles.tags}>
        {tag
          && <>
            <p key={tag.value}
              style={{ backgroundColor: tag.color }}
              >
              {tag.label}
            </p>
            {tags.length > 1
              && <p
              style={{ backgroundColor: tag.color }}
              >
              + {tags.length - 1}
            </p>
            }
          </>
        }
      </div>
      {user.groups.length > 0 && <p className={styles.role}>{user.groups[0]}</p>}
    </div>
  </div>);
};

const FormationLearnersMobile = ({
  learners,
  openModal,
}: {
  learners: any[],
  openModal: () => void,
}) => {
  const { t } = useTranslation('formations');
  return (
    <div className={styles.mobile}>
      <div className={styles.add}>
        <button
          className={`${styles.btn} ${styles.add}`}
          onClick={() => openModal()}
        >
          <IoAdd /> {t('formation.learners.add')}
        </button>
      </div>
      <div className={styles.list}
      >
        {learners.map((l : IUser) => (<Card
          key={l._id}
          user={l}
        />))}
      </div>
  </div>
  );
};

export default FormationLearnersMobile;
