/* eslint-disable @typescript-eslint/no-shadow */
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useNavigate, useParams } from 'react-router-dom';
import PlantsListForm from '../../components/form/PlantsListForm';
import HeaderMobile from '../../components/HeaderMobile';
import {
  useCreatePlantsList,
  useGetPlantsListById,
  useUpdatePlantsList,
} from '../../hooks/plantsList';

import { ErrorField } from '../../lib/HooksFormFields';
import backIcon from '../../assets/images/back-grey.svg';

import styles from './edit.module.scss';

const EditPlantsList = () => {
  const { t } = useTranslation('plantsLists');
  const { id: plantsListId } = useParams();

  const navigate = useNavigate();
  const {
    data: plantsList, isLoading: isGetLoading, error: errorGet,
  } = useGetPlantsListById(plantsListId);

  const back = {
    label: t('plantsList.back'),
    url: '/plantsList',
  };
  // Create
  const {
    mutateAsync: create,
    isLoading: isPostLoading,
    error: errorPost,
  } = useCreatePlantsList();
  // Update
  const {
    mutateAsync: update,
    isLoading: isPutLoading,
    error: errorPut,
  } = useUpdatePlantsList();

  const formPlantsListRef = useRef<any>();
  const errorApi: any = errorGet || errorPost || errorPut;

  const handleSubmit = async () => {
    const data = await formPlantsListRef.current.submit();
    if (!data) return;
    if (plantsList) {
      const { plantsList: updatedPlantsList }: any = await update(data);
      if (updatedPlantsList) navigate('/plantsList');
    } else {
      const { plantsList: newPlantsList }: any = await create(data);
      if (newPlantsList) navigate('/plantsList');
    }
  };

  return (
    <div className={styles.edit}>
       <HeaderMobile back={back} />
      <div className={styles.page}>
        <header>
          <Link to={back.url}>
            <img src={backIcon} alt={back.label} />
            <p>{back.label}</p>
          </Link>
        </header>
        {plantsListId && isGetLoading && <p>Loading</p>}
        {((plantsListId && plantsList) || !plantsListId) && (
          <PlantsListForm
            ref={formPlantsListRef}
            plantsList={plantsList}
            onSubmit={handleSubmit}
            submitIsLoading={isPostLoading || isPutLoading}
          />
        )}
        {errorApi?.message && (
          <ErrorField message={errorApi.message} />
        )}
      </div>
    </div>
  );
};

export default EditPlantsList;
