import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import backIcon from '../../assets/images/back-grey.svg';
import HeaderMobile from '../../components/HeaderMobile';
import styles from './formationDetail.module.scss';
import { useGetFormationById, useUpdateFormation } from '../../hooks/formations';
import FormationQuizListMobile from '../../components/formations/formationQuizListMobile';
import FormationLearnersMobile from '../../components/formations/formationLearnersMobile';
import FormationsQuizModal from '../../components/formations/FormationsQuizModal';
import FormationsUsersModal from '../../components/formations/FormationsUsersModal';
import Modal, { ModalRefProps } from '../../lib/Modal';
import FormationsQuizListDesktop from '../../components/formations/formationsQuizListDesktop';
import FormationsLearnersDesktop from '../../components/formations/formationsLearnersDesktop';

const FormationDetail = () => {
  const { t } = useTranslation('formations');
  const { id } = useParams();
  const addQuizRef = useRef<ModalRefProps | null>(null);
  const addLearnerRef = useRef<ModalRefProps | null>(null);

  const { data: formation } = useGetFormationById(id);
  const [isQuiz, setIsQuiz] = useState(true);
  const quiz = formation?.quiz || [];
  const learners = formation?.learners || [];

  const back = {
    label: t('formation.back'),
    url: '/formations',
  };

  const {
    mutateAsync: updateFormation,
  } = useUpdateFormation();

  return (
    <>
      <div className={styles.formationDetail}>
        <HeaderMobile back={back} />
        <div className={styles.page}>
          <header>
            <Link to={back.url}>
              <img src={backIcon} alt={back.label} />
              <p>{t('btn.back', { ns: 'global' })}</p>
            </Link>
            <div className={styles.links}>
              <button
                className={isQuiz ? styles.active : ''}
                onClick={() => setIsQuiz(true)}
              >
                {t('formation.infos')}
              </button>
              <button
                className={!isQuiz ? styles.active : ''}
                onClick={() => setIsQuiz(false)}
              >
                {t('formation.learners.title')}
              </button>
            </div>
          </header>
          <div className={styles.toggle}>
            <button
              className={isQuiz ? styles.active : ''}
              onClick={() => setIsQuiz(true)}
            >
              {t('formation.infos')}
            </button>
            <button
              className={!isQuiz ? styles.active : ''}
              onClick={() => setIsQuiz(false)}
            >
              {t('formation.learners.title')}
            </button>
          </div>
          <div className={styles.content}>
            {isQuiz
              ? <>
                <FormationQuizListMobile
                  openModal={() => addQuizRef.current?.open()}
                  quiz={quiz}
                />
                <FormationsQuizListDesktop
                  openModal={() => addQuizRef.current?.open()}
                  quiz={quiz}
                  title={formation?.name || ''}
                  updateFormationOrder={(quizList: any[]) => updateFormation({
                    _id: formation?._id,
                    quiz: quizList,
                  })}
                />
              </>
              : <>
              <FormationLearnersMobile
                openModal={() => addLearnerRef.current?.open()}
                learners={learners}
              />
              <FormationsLearnersDesktop
                  title={formation?.name || ''}
                  openModal={() => addLearnerRef.current?.open()}
                  learners={learners}
              />
              </>
            }
          </div>
        </div>
      </div>
      <Modal
        ref={addQuizRef}
      >
        <FormationsQuizModal
          close={() => addQuizRef?.current?.close()}
        />
      </Modal>
      <Modal
        ref={addLearnerRef}
      >
        <FormationsUsersModal
          close={() => addLearnerRef?.current?.close()}
        />
      </Modal>
      </>
  );
};

export default FormationDetail;
