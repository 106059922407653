import React from 'react';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { UserQuizItem } from '../../../types/userQuiz';
import { getApiPicture } from '../../../utils';
import { getCurrentQuizAction } from '../../../actions/userQuiz';
import defaultPicture from '../../../assets/images/plante.jpg';
import styles from './quizList.module.scss';
import { IQuiz } from '../../../types/quiz';

const QuizLink = (
  {
    quiz,
    isFormation,
  }
  :{
    quiz: UserQuizItem,
    isFormation?: boolean,
  },
) => {
  const picture = quiz?.picture?.path ? getApiPicture(quiz?.picture?.path) : defaultPicture;
  const dispatch = useDispatch();
  return (
    <button
      type="button"
      onClick={() => getCurrentQuizAction(dispatch, quiz._id)}
      className={`${styles.link} ${isFormation && !quiz.isNext ? styles.disabled : ''}`}>
      <div className={styles.picture}>
        {picture && <img src={picture} />}
      </div>
      <div className={styles.infos}>
        <p>{quiz.name}</p>
        <p>{quiz.countPlants || quiz?.plants?.length} Plantes</p>
      </div>
      {quiz.score && <span className={styles.score}>{quiz.score}/{quiz?.questions?.length}</span>}
      <div className={styles.count}>
        <p>{quiz.countQuestions || quiz?.questions?.length}</p>
        <BsQuestionCircleFill />
      </div>
    </button>
  );
};

export default function QuizList({
  list = [],
  label,
  isFormation = false,
}:{
  list?: UserQuizItem[] | IQuiz[];
  label?: string
  isFormation?: boolean,
}) {
  return (
    <div className={`${styles.container}`}>
      {label && <label>{label}</label>}
      <div className={styles.list}>
        {list.map((q: any) => <QuizLink
          key={q._id}
          quiz={q}
          isFormation={isFormation}
        />)}
      </div>
    </div>
  );
}
