import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, NavLink, useLocation, useNavigate,
} from 'react-router-dom';
import logo from './images/logo.svg';
import statIcon from './images/stat.svg';
import actusIcon from './images/actu.svg';
import usersIcon from './images/member.svg';
import plantsListIcon from './images/plantsList.svg';
import quizIcon from './images/quiz.svg';
import formationIcon from './images/formation.svg';
import logout from './images/logout.svg';
import subscriptionIcon from './images/subscription.svg';
import close from './images/close.svg';
import styles from './nav.module.scss';
import { AUTH_LOGOUT, SET_MENU_IS_OPEN } from '../../actions/types';

const Nav = () => {
  const { user, menuIsOpen } = useSelector((d: any) => d.authReducer);
  const dispatch = useDispatch();
  const { t } = useTranslation('global');
  const location = useLocation();
  const navigate = useNavigate();

  const getActiveStyle = useCallback((link: string) => {
    if (location?.pathname.includes(link) && link !== '/') {
      return styles.active;
    }
    if (location?.pathname === link) {
      return styles.active;
    }
    return '';
  }, [location]);

  const links = [
    {
      label: t('nav.dashboard'),
      link: '/',
      color: '#FF845E',
      icon: statIcon,
      roles: ['Owner', 'Tutor', 'Learner'],
    },
    {
      label: t('nav.actus'),
      link: '/actus',
      color: '#419FB4',
      icon: actusIcon,
      roles: ['Owner', 'Tutor'],
    },
    {
      label: t('nav.users'),
      link: '/users',
      color: '#CB6060',
      icon: usersIcon,
      roles: ['Owner', 'Tutor'],
    },
    {
      label: t('nav.quiz'),
      link: '/user-quiz',
      color: '#45c4a6',
      icon: quizIcon,
      roles: ['Learner'],
    },
    {
      label: t('nav.formations'),
      link: '/formations',
      color: '#EFBD14',
      icon: formationIcon,
      roles: ['Tutor', 'Learner'],
    },
    {
      label: t('nav.plantsList'),
      link: '/plantsList',
      color: '#cab5f5',
      icon: plantsListIcon,
      roles: ['Tutor'],
    },
    {
      label: t('nav.subscription'),
      link: '/subscription',
      color: '#815AD3',
      icon: subscriptionIcon,
      roles: ['Owner'],
    },
  ];

  const usersLinks = useMemo(() => links.filter(
    (l: any) => l.roles.includes(user.role),
  ), [user.role]);

  function closeMenu() {
    return dispatch({
      type: SET_MENU_IS_OPEN,
      payload: false,
    });
  }

  async function handleLogout() {
    await dispatch({ type: AUTH_LOGOUT });
    await navigate('/login');
  }

  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.top}>
          <Link to="/">
            <img src={logo} alt="logo-tepik" />
          </Link>
          <div className={styles.links}>
            {usersLinks.map((l: any) => (
              <NavLink key={l.link} to={l.link}>
                <div className={styles.icon}
                >
                  <div
                    className={`${styles.bg} ${getActiveStyle(l.link)}`}
                    style={{
                      backgroundColor: l.color,
                    }}
                  />
                  <img src={l.icon} alt={l.label} />
                </div>
                <p>{l.label}</p>
              </NavLink>
            ))}
          </div>
        </div>
        <div className={styles.bottom}>
          <Link to={'/profile'} className={styles.profile}>
            {user?.picture?.path
              ? <img src={`${process.env.REACT_APP_API_URL}/files/public/${user.picture.path}`} alt="picture" />
              : <span>
            {user?.profile?.firstName[0]}
            {user?.profile?.lastName[0]}
            </span>
            }
          </Link>
          <button
            className={styles.logout}
            onClick={() => handleLogout()}
          >
            <img src={logout} alt='logout' />
          </button>
        </div>
      </div>
      <div className={`${styles.menu} ${menuIsOpen ? styles.open : ''}`}>
        <header>
          <h1>{t('nav.menu')}</h1>
          <button
            type="button"
            onClick={() => closeMenu()}
          >
            <img src={close} alt='logout' />
          </button>
        </header>
        <div className={styles.links}>
          {usersLinks.map((l: any) => (
            <NavLink
              onClick={() => closeMenu()}
              key={l.link}
              to={l.link}
            >
              <div className={styles.icon}
              >
                <div
                  className={`${styles.bg} ${getActiveStyle(l.link)}`}
                  style={{
                    backgroundColor: l.color,
                  }}
                />
                <img src={l.icon} alt={l.label} />
              </div>
              <p>{l.label}</p>
            </NavLink>
          ))}
        </div>
        <Link
          to={'/profile'}
          className={styles.profile}
          onClick={() => closeMenu()}
        >
          <div className={styles.circle}>
            {user?.profile?.firstName[0]}
            {user?.profile?.lastName[0]}
          </div>
          <p>{t('title.profile', { ns: 'users' })}</p>
        </Link>
        <button
          className={styles.logout}
          onClick={() => handleLogout()}
        >
          <img src={logout} alt='logout' />
          <p>{t('logout', { ns: 'auth' })}</p>
        </button>
      </div>
    </>
  );
};

export default Nav;
