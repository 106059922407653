/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {
  useQuery,
} from '@tanstack/react-query';
import {
  getArticleById, getArticles, getArticlesList,
} from '../../api/articles';

import { ArgsGetArticles, IArticle } from '../../types/article';

const useGetArticles = (params: ArgsGetArticles) => useQuery({
  queryKey: ['Articles', params],
  queryFn: async (): Promise<any | null> => getArticles(params),
  keepPreviousData: true,
});

const useGetArticlesList = () => useQuery({
  queryKey: ['ArticlesList'],
  queryFn: async (): Promise<any | null> => getArticlesList(),
  keepPreviousData: true,
});

const useGetArticleById = (id?: string) => useQuery({
  queryKey: ['Article', id],
  queryFn: async (): Promise<IArticle | null | undefined> => getArticleById(id),
  keepPreviousData: true,
  enabled: !!id,
});

export {
  useGetArticles,
  useGetArticleById,
  useGetArticlesList,
};
