import React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { IoChevronUp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { format } from 'date-fns';
import styles from './plantsListsDesktop.module.scss';
import InputSearch from '../../InputSearch';
import FilterSearchParam from '../../FilterSearchParam';
import useTagsToOptionsLang from '../../../hooks/useTagsToOptionsLang';
import { IPlantsList } from '../../../types/plantsList';

const Row = ({ plantsList }: { plantsList: IPlantsList }) => {
  const tags = useTagsToOptionsLang(plantsList?.tags || []);
  const tag = tags.length > 0 ? tags[0] : null;
  const formatedDate = format(new Date(plantsList?.createdAt), 'dd/MM/yyyy');
  return (<Link to={`/plantsList/${plantsList._id}`}
      className={styles.row}
    >
    <div className={styles.col}>
      <p>{plantsList.name}</p>
    </div>
    <div className={styles.col}>
      {formatedDate}
    </div>
    <div className={styles.col}>
      <div className={styles.tags}>
          {tag
            && <>
              <p key={tag.value}
                style={{ backgroundColor: tag.color }}
                >
                {tag.label}
              </p>
              {tags.length > 1
                && <p
                style={{ backgroundColor: tag.color }}
                >
                + {tags.length - 1}
              </p>
              }
            </>
          }
        </div>
    </div>
  </Link>);
};

const PlantsListsDesktop = ({
  plantsLists,
  tags,
  title,
}: {
  plantsLists: IPlantsList[],
  tags: any,
  title: string,
}) => {
  const { t } = useTranslation('plantsLists');
  const [searchParams, setSearchParams] = useSearchParams();
  const { search = '', order = 'desc', sortKey = '' } = Object.fromEntries(searchParams);

  const handleSearch = (searchValue: string) => {
    const params = new URLSearchParams(searchParams);
    if (searchValue.length < 1) {
      params.delete('search');
    } else {
      params.set('search', searchValue);
    }
    setSearchParams(params);
  };

  const handleSorting = (key: any) => {
    if (!key) return;
    const params = new URLSearchParams(searchParams);
    params.set('sortKey', key);
    params.set('order', order === 'asc' ? 'desc' : 'asc');
    setSearchParams(new URLSearchParams(params));
  };

  const labels = [
    {
      label: t('plantsList.label.name'),
    },
    {
      label: t('plantsList.label.createdAt'),
      sortKey: 'createdAt',
    },
    {
      label: t('plantsList.label.tags'),
    },
  ];

  return (
    <div className={styles.desktop}>
    <div className={styles.header}>
      <h1>{title}</h1>
      <div className={styles.actions}>
        <div className={styles.search}>
          <InputSearch
            defaultValue={search}
            handleSearch={handleSearch}
            isToggle={true}
          />
        </div>
        <Link to={'/plantsList/create'}
          className={`${styles.btn} ${styles.add}`}
        >
          <IoIosAdd />{t('plantsList.new')}
        </Link>
      </div>
    </div>
    <div className={styles.filters}>
      <div className={styles.filter}>
        <FilterSearchParam
          label={t('filters.tags')}
          name='tag'
          options={tags || []}
        />
      </div>
    </div>
    <div className={styles.content}>
      <div className={`${styles.row} ${styles.label}`}>
        {labels.map((l: any) => <div key={l.label}
          className={`${styles.col} ${l?.sortKey ? styles.sort : ''} ${order === 'desc' ? styles.desc : ''}`}
          onClick={() => handleSorting(l?.sortKey)}
        >
          {l?.sortKey === sortKey && <div className={styles.icon}>
            <IoChevronUp />
          </div>
          }
          <p>{l.label}</p>
        </div>)}
      </div>
      <div className={styles.list}
      >
        {plantsLists.map((p: IPlantsList) => (<Row
          key={p._id}
          plantsList={p}
        />))}
      </div>
    </div>
  </div>
  );
};
export default PlantsListsDesktop;
