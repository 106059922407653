import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import backIcon from './images/back.svg';
import menu from './images/menu.svg';
import styles from './header.module.scss';
import { SET_MENU_IS_OPEN } from '../../actions/types';

type Back = {
  label: string,
  url: string,
};

const HeaderMobile = ({
  title = null,
  back = null,
} : {
  title?: string | null,
  back?: Back | null
}) => {
  const dispatch = useDispatch();
  return (
  <div className={styles.header}>
    {title && <h1>{title}</h1>}
    {back && <Link to={back?.url}>
      <img src={backIcon} alt={back.label} />
      <p>{back.label}</p>
    </Link>}
    <button
      type="button"
      onClick={() => dispatch({
        type: SET_MENU_IS_OPEN,
        payload: true,
      })}
      className={styles.toggle}>
      <img src={menu} alt="menu"/>
    </button>
  </div>
  );
};

export default HeaderMobile;
