/* eslint-disable import/extensions */
import React, { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import imgLogin from '../../assets/images/login.svg';
import logo from '../../assets/images/logo.svg';
import imgPlant from '../../assets/images/plant.svg';
import SignInForm from '../../components/form/AuthForm/SignInForm';

import styles from './signin.module.scss';
import { getProfileAction, signIn } from '../../actions/auth';

const SignIn = () => {
  const { user, error } = useSelector((d: any) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('auth');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getProfileAction(dispatch);
    }
  }, []);

  useEffect(() => {
    if (user?.role) {
      if (window?.location?.href && user?.role === 'Admin') {
        (window as any).location.href = process.env.REACT_APP_ADMIN_URL;
        localStorage.removeItem('token');
      } else if (user?.organisations?.length > 1) {
        navigate('/organisation');
      } else {
        localStorage.setItem('organisation', user.organisations[0]?._id);
        navigate('/');
      }
    }
  }, [user]);

  function submit(data: FieldValues) {
    signIn(dispatch, data);
  }

  return (
    <div className={styles.signin}>
      <div className={styles.picture}>
        <div className={styles.mobile}>
          <img src={imgPlant} alt="login" />
          <p>{t('login.welcome')}</p>
          <h2>{t('login.start1')}<br/>{t('login.start2')}</h2>
        </div>
        <div className={styles.desktop}>
          <div className={styles.logo}>
            <img src={logo} alt="login" />
          </div>
          <img src={imgLogin} alt="login" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.form}>
          <h3>{t('login.submit')}</h3>
          <SignInForm
            forgotPwdLink="/forgot-password"
            errorMessage={error}
            submit={submit}
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
